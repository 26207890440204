import {
    MYACCOUNT_DASHBOARD,
    MYACCOUNT_INBOX,
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_CALENDAR,
    MYACCOUNT_PROFILE,
    FRONTEND_SITE_URL,
    WEBSITE_URL,
    MYACCOUNT_LIGHT,
    MYACCOUNT_FAQ,
} from "./src/core/constants/urls"

import { FAQ } from "././src/core/StarberryComponentsMui/features"
import { createSearchUrl } from "./src/core/components/MyAccount/pages/properties/functions"

import CustomBedsIcon from "./src/assets/icons/martyngerrard/bedrooms.inline.svg"
import CustomBathIcon from "./src/assets/icons/martyngerrard/bathrooms.inline.svg"
import CustomRecIcon from "./src/assets/icons/martyngerrard/receptions.inline.svg"
import CustomLogoIcon from "./src/assets/icons/dng/logo.inline.svg"

// Heading definitions h1 - h6 + line heights
const h1Size = "4.125rem", // 1rem === htmlFontSize
    h1LineHSize = 1.09, // lineHeight / fontSize
    h2Size = "3.125rem",
    h2LineHSize = 1.28,
    h3Size = "2.625rem",
    h3LineHSize = 1.14,
    h4Size = "2rem",
    h4LineHSize = 1.25,
    h5Size = "1.5rem",
    h5LineHSize = 1.33,
    h6Size = "1.25rem",
    h6LineHSize = 1.4

// body size custom definitions
const bodyXSmallSize = "0.6875rem",
    lineHeightBXSmallSize = 1.63,
    bodySmallSize = "0.875rem",
    lineHeightBSmallSize = 1.43,
    bodyMediumSize = "1rem",
    lineHeightBMediumSize = 1.5,
    bodySmallLargeSize = "1.125rem",
    lineHeightBSLargeSize = 1.68,
    bodyLargeSize = "1.25rem",
    lineHeightBLargeSize = 1.44,
    bodyXLargeSize = "1.5rem",
    lineHeightBXLargeSize = 1.55

// Custom grid column/container values
const gridItemPmobileValue = "1rem",
    gridItemPtabletValue = "2.5rem",
    gridItemPdesktopValue = "1.875rem"
//   gridItemPdesktopValue = "3.75rem";

const shadowKeyUmbraOpacity = 0.1
const shadowKeyPenumbraOpacity = 0.1
const shadowAmbientShadowOpacity = 0.1
function createShadow(...px) {
    return [
        `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
        `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
        `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
    ].join(",")
}

const propertyLink = p => {
    let sectorPath = p.sector || p.property_type || p.search_type
    if (sectorPath) {
        sectorPath = sectorPath.replace(" ", "-")
        sectorPath = sectorPath === "to-let" ? "to-rent" : sectorPath
    }
    return `${FRONTEND_SITE_URL}/property-${sectorPath}/${p.slug}-${
        p.objectID || p.id
    }`
}

const resultLinkFun = params => {
    const basePathVal = {
        residential: "property",
        commercial: "commercial-property",
        new_developments: "new-homes",
    }
    return `${FRONTEND_SITE_URL}${createSearchUrl(params, {
        useStatus: true,
        minPricePrefix: "above",
        maxPricePrefix: "under",
        basePath: basePathVal,
        statusDelimiter: "/",
        areaPrefix: "in-",
        typePrefix: "type-",
        defaultArea: defaultArea,
    })}`
}

const themeName = "dng"

const termsSiteLink = `${FRONTEND_SITE_URL}/terms-and-conditions`
const privacySiteLink = `${FRONTEND_SITE_URL}/privacy-policy`
const defaultArea = "Ireland"
const defaultCountry = "IE"
const currencyCode = "EUR"
const currencySymbol = "€"
const identityChecks = true

export default {
    // Redefine the custom heading values so we have them available from the {theme} object
    h1: h1Size,
    h1LineH: h1LineHSize,
    h2: h2Size,
    h2LineH: h2LineHSize,
    h3: h3Size,
    h3LineH: h3LineHSize,
    h4: h4Size,
    h4LineH: h4LineHSize,
    h5: h5Size,
    h5LineH: h5LineHSize,
    h6: h6Size,
    h6LineH: h6LineHSize,

    // Redefine the custom body values so we have them available from the {theme} object
    bodyXSmall: bodyXSmallSize,
    lineHeightBXSmall: lineHeightBXSmallSize,
    bodySmall: bodySmallSize,
    lineHeightBSmall: lineHeightBSmallSize,
    bodyMedium: bodyMediumSize,
    lineHeightBMedium: lineHeightBMediumSize,
    bodySmallLarge: bodySmallLargeSize,
    lineHeightBSLarge: lineHeightBSLargeSize,
    bodyLarge: bodyLargeSize,
    lineHeightBLarge: lineHeightBLargeSize,
    bodyXLarge: bodyXLargeSize,
    lineHeightBXLarge: lineHeightBXLargeSize,

    // Redefine custom grid column/container values so we have them available from the {theme} object
    gridItemPmobile: `${gridItemPmobileValue}`,
    gridItemPtablet: `${gridItemPtabletValue}`,
    gridItemPdesktop: `${gridItemPdesktopValue}`,

    /**
     * MUI GLOBAL OVERRIDES OF STYLES
     */
    mixins: theme => ({
        toolbar: {
            minHeight: 60,
            [theme.breakpoints.up("md")]: {
                minHeight: 96,
            },
        },
    }),
    palette: {
        primary: {
            // light: '#757ce8',
            main: "#C8102E",
            dark: "#0C2340",
            heading: "#232125",
            title: "#121212",
            icon: "#C8102E",
            contrastText: "#fff",
        },
        secondary: {
            // light: '#ff7961',
            main: "#C8102E",
            dark: "#1B1434",
            contrastText: "#fff",
        },
        accent: {
            main: "#005480",
        },
        divider: "#D8D8D8",
        background: {
            paper: "#fff",
            default: "#fff",
        },
        grey: {
            // custom grey values
            grey1: "#555C65",
            grey2: "#9CA7B4",
            grey3: "#DCDCDC",
            grey4: "#F6F6F5",
            grey5: "#FAFAFA",
        },
        text: {
            primary: "#555C65",
            secondary: "#6A6A6A",
        },
        icon: {
            loginLogo: "#1B1434",
        },
    },
    shadows: [
        "none",
        createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
        createShadow(0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0), // elevation={2} is custom changed for module bgs
        createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
        createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
        createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
        createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
        createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
        createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
        createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
        createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
        createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
        createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
        createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
        createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
        createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
        createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
        createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
        createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
        createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
        createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
        createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
        createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
        createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
        createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
    ],
    shape: {
        borderRadius: 3,
    },
    spacing: factor => (factor !== "auto" ? `${0.5 * factor}rem` : factor), // this translates to 8px
    components: {
        MuiContainer: {
            // this <Container> has to be the parent of Mui Grid `container` item element
            styleOverrides: {
                maxWidthXl: {
                    paddingLeft: `${gridItemPmobileValue}`,
                    paddingRight: `${gridItemPmobileValue}`,
                    "@media screen and (min-width: 600px)": {
                        paddingLeft: `${gridItemPtabletValue}`,
                        paddingRight: `${gridItemPtabletValue}`,
                    },
                    "@media screen and (min-width: 1280px)": {
                        paddingLeft: `calc(${gridItemPdesktopValue} * 2)`,
                        paddingRight: `calc(${gridItemPdesktopValue} * 2)`,
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                // I've selected the spacing={1} to be a custom theme spacing grid for the use of the project
                // We change the horizontal spacings of items on different breakpoints and Remove vertical spacing
                // In the project each component should ideally be inside the grid item so the spacings are used consistently
                container: {
                    "&.MuiGrid-spacing-xs-1": {
                        width: `calc(100% + (${gridItemPmobileValue} * 2))`,
                        margin: `0 -${gridItemPmobileValue}`,
                        "@media screen and (min-width: 600px)": {
                            width: `calc(100% + (${gridItemPtabletValue} * 2))`,
                            margin: `0 -${gridItemPtabletValue}`,
                        },
                        "@media screen and (min-width: 1280px)": {
                            width: `calc(100% + (${gridItemPdesktopValue} * 2))`,
                            margin: `0 -${gridItemPdesktopValue}`,
                        },
                        "> .MuiGrid-item": {
                            padding: `0 ${gridItemPmobileValue}`,
                            "@media screen and (min-width: 600px)": {
                                padding: `0 ${gridItemPtabletValue}`,
                            },
                            "@media screen and (min-width: 1280px)": {
                                padding: `0 ${gridItemPdesktopValue}`,
                            },
                        },
                    },
                },
            },
        },
        MuiTypography: {
            // adding custom defined typography variants to use them as `props`
            variants: [
                {
                    props: { variant: "bodyXSmall" },
                    style: {
                        fontSize: bodyXSmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXSmallSize,
                    },
                },
                {
                    props: { variant: "bodySmall" },
                    style: {
                        fontSize: bodySmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBSmallSize,
                    },
                },
                {
                    props: { variant: "bodyMedium" },
                    style: {
                        fontSize: bodyMediumSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBMediumSize,
                    },
                },
                {
                    props: { variant: "bodyLarge" },
                    style: {
                        fontSize: bodyLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBLargeSize,
                    },
                },
                {
                    props: { variant: "bodyXLarge" },
                    style: {
                        fontSize: bodyXLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXLargeSize,
                    },
                },
            ],
        },
        MuiButton: {
            defaultProps: {
                // variant: "contained",
            },
            styleOverrides: {
                root: {
                    borderRadius: "0px",
                    textTransform: "uppercase",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    "@media (min-width: 960px)": {
                        width: "auto",
                    },
                },
                label: {
                    // fontFamily: '"GillSansNova-SemiBold", "Helvetica", "Arial", sans-serif',
                    // letterSpacing: "0.03em",
                },
                containedPrimary: {
                    background: "#C8102E",
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                },
                containedSecondary: {
                    background: "#C8102E",
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                },
                sizeMedium: {
                    padding: "10px 26px",
                },
                sizeSmall: {
                    fontSize: "12px",
                    "@media (min-width: 991px)": {
                        fontSize: "14px",
                    },
                },
            },
            variants: [
                {
                    props: { variant: "white" },
                    style: {
                        background: "#fff",
                        boxShadow: "none",
                        fontWeight: "bold",
                        "&:hover": {
                            background:
                                "linear-gradient(225deg, #1663B3 0%, #217698 100%)",
                            color: "#fff",
                        },
                    },
                },
            ],
        },
        MuiPickersCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: bodySmallSize,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: bodySmallSize,
                },
            },
        },
        MuiClock: {
            styleOverrides: {
                root: {
                    position: "relative", // Fix to position AM/PM inside the clock area
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    "& label.Mui-focused": {
                        color: "#0C2340",
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C2340",
                    },
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#0C2340",
                        },
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#005480",
                    padding: "12px 16px",
                    fontSize: "14px",
                },
                arrow: {
                    "&::before": {
                        backgroundColor: "#005480",
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: "large",
            },
        },
    },
    typography: {
        htmlFontSize: 16,
        // fontFamily: 'Switzer-Light', // for custom google font you need to include the <link> to the font in the header of the page, this is not done with MUI
        fontFamily: '"Roboto"',

        // fontWeightBold: 700,
        h1: {
            fontFamily: '"BebasNeue"',
            fontSize: h1Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h1LineHSize, // lineHeight / fontSize
        },
        h2: {
            fontFamily: '"BebasNeue"',
            fontSize: h2Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h2LineHSize,
        },
        h3: {
            fontFamily: '"BebasNeue"',
            fontSize: h3Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h3LineHSize,
        },
        h4: {
            fontFamily: '"BebasNeue"',
            fontSize: h4Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h4LineHSize,
        },
        h5: {
            fontFamily: '"BebasNeue"',
            fontSize: h5Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h5LineHSize,
        },
        h6: {
            fontFamily: "BebasNeue",
            fontSize: h6Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h6LineHSize,
        },
        body1: {
            fontSize: "1.125rem",
            letterSpacing: "-0.01em",
            lineHeight: 1.44,
        },
        caption: {
            fontSize: "0.6875rem",
        },
        button: {
            // fontFamily: 'Switzer-Light',
            fontWeight: 500,
            letterSpacing: "0.03em",
        },
    },

    // Defining global google fonts to be added in the <head>
    // googleFont: "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
    primaryFontFamily: '"BebasNeue"',
    secondaryFontFamily: '"Roboto"',
    tertiaryFontFamily: '"Roboto"',

    // Global CSS implemneted by StarberryRootThemeProvider.
    sx: {
        "@global": {
            iframe: {
                border: "none",
            },
            "body.floatingContainer-toolbar main": {
                // add additional spacing on bottom if we have floating toolbars
                "@media (max-width: 959px)": {
                    paddingBottom: "10rem",
                },
            },
            "@font-face": [
                {
                    fontFamily: "BebasNeue",
                    src: "url('/fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'), url('/fonts/ModernEra/BebasNeue-Regular.woff') format('woff')",
                    fontWeight: 600,
                    fontStyle: "normal",
                    color: "#555C65",
                },
                {
                    fontFamily: "Roboto",
                    src: "url('/fonts/Roboto/Roboto-Medium.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Medium.woff') format('woff')",
                },
            ],
        },
    },

    /**
     * END MUI GLOBAL OVERRIDES OF STYLES
     */

    /**
     * Starberry custom theme variables
     */

    // Sidebar drawer navigation
    myAccountSetDrawerWidth: 340,

    // Main content area paddings
    myAccountContentPTmobile: 2.25, // top spacing for the content container
    myAccountContentPBmobile: 2.25, // bottom spacing for the content container

    myAccountContentPTtablet: 4, // top spacing for the content container
    myAccountContentPBtablet: 4, // bottom spacing for the content container

    myAccountContentPTDesktop: 6, // top spacing for the content container
    myAccountContentPBDesktop: 6, // bottom spacing for the content container

    // module border radius if its different then MUI border radius
    moduleBorderRadius: "14px",
    // Global sidebar module padding
    modulePadding: theme => theme.spacing(4, 5),
    moduleSpacing: theme => theme.spacing(2.6),
    moduleSpacingSm: theme => theme.spacing(6),
    moduleSpacingMd: theme => theme.spacing(6),
    moduleSpacingLg: theme => theme.spacing(7),
    moduleSpacingXl: theme => theme.spacing(7),

    /**
     * End Starberry custom theme variables
     */

    // The name of the theme.  This is used for naming elements in the
    // site.
    name: themeName,

    // This is the theme name that helps to load theme specific items
    themeName: themeName,

    // Site terms & privacy link
    termsSiteLink: termsSiteLink,
    termsThemeLabel: "Terms & Conditions",
    privacySiteLink: privacySiteLink,
    privacyThemeLabel: "Privacy Policy",

    // The company name, which can be used for the copyright message, and
    // anywhere else it might be needed.
    companyName: "DNG Group Limited",

    // The copyright message of the site, for the footer.
    copyright: theme => `© ${theme.year}, ${theme.companyName}`,

    // Simple abstraction to make the copyright message above a little
    // easier to read.
    year: `${new Date().getFullYear()}`,
    currencyCode: currencyCode,

    // Used by <Breadcrumbs>
    breadcrumbSeparator: "… ",

    // Social media links used by <FooterSocial>
    social: {
        Instagram: "https://www.instagram.com/starberrylife/",
        Twitter: "https://twitter.com/starberry",
        YouTube: "https://www.youtube.com/channel/UCAPHSlQISzaD-384O__9QhQ",
        LinkedIn: "https://www.linkedin.com/company/starberry",
    },

    images: {
        default: { method: "img" },
        propertyresult: {
            method: "img",
            sx: {
                "& img": {
                    width: "100%",
                    height: "auto",
                    transform: "scale(1)",
                    transition: "transform .2s ease-out",
                },
                "&:hover": {
                    transform: "scale(1.3)",
                },
            },
        },
    },
    loginBg: `/images/${themeName}/login-signup.jpg`,

    logo: `

        <svg width="180" height="64" viewBox="0 0 180 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71989 1.97876V62.0107H30.3631C48.3778 62.0107 64.3304 52.2328 64.3304 30.2847C64.3304 15.7887 55.0581 1.97876 31.0453 1.97876H7.71989ZM25.8178 13.2223H31.0453C38.9391 13.2223 45.7154 19.4725 45.7154 30.3704C45.7154 42.4639 40.8258 50.7788 30.4501 50.7788H25.8178V13.2223Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M121.296 62.0113H109.2C100.198 51.1287 88.0175 36.8029 82.2741 26.8522H82.0962V62.0113H67.8498V1.97925H80.9916C89.6431 12.6084 102.592 28.2205 106.886 36.3844H107.055V1.97925H121.296V62.0113Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M179.598 26.1274V59.9604C172.307 62.1108 166.217 63.0426 160.728 63.0426C140.743 63.0426 124.285 51.0324 124.285 31.4049C124.285 13.2225 139.286 0.95752 159.611 0.95752C163.552 0.95752 170.675 2.0596 174.96 3.95784L170.498 14.5895C167.586 13.7255 162.267 13.4746 160.555 13.4746C151.975 13.4746 143.142 19.0554 143.142 32.0846C143.142 44.8628 152.67 50.5294 158.838 50.5294C159.443 50.5294 160.38 50.4308 161.501 50.0954V34.5665L179.598 26.1274Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71354 1.92383H0.482821V2.97727C0.481542 4.12031 1.1241 4.80383 2.19034 4.80383C2.97242 4.80511 3.71226 4.35199 3.71354 3.03871V1.92383ZM3.28218 2.57407L3.2809 3.04511C3.27834 3.72735 2.86234 4.11263 2.19418 4.11263C1.29562 4.11135 0.912902 3.70943 0.914179 2.96831V2.57279L3.28218 2.57407Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.424263 6.82497C0.424263 7.81441 1.17562 8.43265 2.14202 8.43265C3.0457 8.43265 3.76762 7.75169 3.76762 6.82625C3.76634 5.90081 3.04698 5.21729 2.1433 5.21729C1.17946 5.21601 0.424263 5.83425 0.424263 6.82497ZM0.885063 6.82497C0.885063 6.14657 1.52506 5.90849 2.1433 5.90849C2.73722 5.90977 3.30554 6.17985 3.30682 6.82497C3.30554 7.47137 2.7385 7.74401 2.14202 7.74401C1.52378 7.74273 0.885063 7.50209 0.885063 6.82497Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71104 9.64947L1.85504 9.64819C1.22144 9.64819 0.87584 9.83251 0.87456 10.3483C0.87456 10.8437 1.29696 11.0395 1.8192 11.0408H3.70848V11.6219H1.70272C0.88224 11.6207 0.422717 11.0946 0.422717 10.3304C0.42144 9.45491 0.85536 8.99795 1.736 9.0005H3.71104V9.64947Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.95412 15.073L0.620364 15.0717C0.506444 14.7261 0.420684 14.3601 0.42195 13.994C0.42195 12.8791 1.16052 12.1738 2.11156 12.1738C2.99476 12.1751 3.76532 12.874 3.76405 14.0106C3.76405 14.3677 3.6514 14.7236 3.51316 14.9348L3.13044 14.7261C3.2226 14.5508 3.30452 14.2794 3.30196 14.0311C3.30452 13.3412 2.83732 12.8637 2.1026 12.8625C1.33716 12.8637 0.882764 13.4103 0.882764 13.9645C0.882764 14.1412 0.900684 14.2833 0.962124 14.4228L1.95412 14.4215V15.073Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7067 15.8694V16.521L0.907343 16.5197L0.908623 17.7984H0.475983L0.477263 15.8682L3.7067 15.8694Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7055 19.1962L0.476063 17.8804V18.4423L1.35798 18.7764L1.35926 19.9181L0.473503 20.2164V20.9434L3.7055 19.7338V19.1962ZM1.75478 18.9479C2.17846 19.0989 2.57398 19.25 3.06806 19.3844V19.3933C2.5727 19.5457 2.17718 19.6826 1.75222 19.8221L1.75478 18.9479Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.14204 22.9375C3.23676 22.7954 3.32508 22.6047 3.32508 22.4166C3.32636 22.1426 3.17532 21.9954 2.98332 21.9967C2.77212 21.9967 2.61212 22.2207 2.30748 22.5983C1.96444 23.0335 1.6918 23.2626 1.29372 23.2626C0.887964 23.2626 0.416924 22.8761 0.416924 22.1452C0.418204 21.8098 0.519324 21.4399 0.742044 21.198L1.1094 21.4335C0.985244 21.6038 0.850844 21.8022 0.852124 22.0927C0.852124 22.3602 0.996764 22.5932 1.30012 22.5945C1.6534 22.5945 1.88892 22.1631 2.09244 21.9033C2.34844 21.591 2.54556 21.3286 2.97692 21.3298C3.41084 21.3311 3.759 21.765 3.76028 22.3513C3.759 22.7212 3.62716 23.0143 3.47868 23.1666L3.14204 22.9375Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.471489 28.0579V27.5882C1.21517 26.9686 1.95373 26.353 2.78701 25.7462V25.7386L0.471489 25.736L0.472769 25.2163L3.70349 25.2176V25.6925C2.96621 26.3094 2.23021 26.92 1.39949 27.528V27.537L3.70093 27.5382L3.70349 28.0592L0.471489 28.0579Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.26803 30.5569V29.474L2.38099 29.4727L2.38227 30.5326L1.94707 30.5313V29.474L0.903868 29.4727L0.902588 30.7515L0.469948 30.7502V28.8225L3.70195 28.8238L3.69939 30.5569H3.26803Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69953 33.5331C2.86881 33.7903 2.04321 34.0515 1.10242 34.3049L1.10114 34.3126C2.05473 34.5302 2.90081 34.7529 3.74561 34.9731L3.65089 35.503L0.466255 34.5955L0.468815 33.9311C1.23425 33.6931 2.00481 33.4511 2.88417 33.2182V33.2092C2.00481 32.9775 1.23425 32.7484 0.468815 32.5142V31.8435L3.65601 30.9487L3.74817 31.5887C2.90209 31.8051 2.05473 32.015 1.1037 32.2262V32.2351C2.04449 32.4899 2.87137 32.7471 3.69953 33.0057V33.5331Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.466499 39.5606V38.9116L2.98682 38.9129V38.9039C2.0857 38.5468 1.2729 38.1782 0.466499 37.8159V37.4639C1.2729 37.1183 2.0857 36.7715 2.9881 36.4335V36.4259L0.466499 36.4233V35.9023L3.6985 35.9036L3.69722 36.7049C2.90618 37.0364 1.63898 37.5702 1.22298 37.6956V37.7046C1.6569 37.8569 2.9049 38.4406 3.69594 38.8067V39.5606H0.466499Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69661 41.2636L0.465895 39.9478V40.5097L1.34781 40.8425L1.34909 41.9842L0.464615 42.2825V43.0121L3.69661 41.7999V41.2636ZM1.74205 41.0127C2.16957 41.165 2.56381 41.3174 3.05661 41.4518V41.4594C2.56253 41.6118 2.16829 41.7513 1.74333 41.8882L1.74205 41.0127Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.461685 46.2419L0.462965 45.7721C1.20665 45.1526 1.94393 44.5356 2.77976 43.9263V43.9212L0.462965 43.9199L0.464245 43.3977L3.69624 43.399V43.8739C2.95768 44.4908 2.22168 45.1052 1.38841 45.7119V45.7196L3.69368 45.7209V46.2431L0.461685 46.2419Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.93919 51.053H0.60543C0.49151 50.7061 0.40447 50.3388 0.40703 49.9727C0.40575 48.8578 1.14303 48.1525 2.09535 48.1538C2.97855 48.1538 3.74911 48.854 3.74784 49.9906C3.74911 50.3465 3.63647 50.701 3.49823 50.9148L3.11551 50.7061C3.20767 50.5295 3.28831 50.2594 3.28703 50.0085C3.28575 49.3199 2.81983 48.8424 2.08767 48.8424C1.31967 48.8424 0.86783 49.3865 0.86783 49.9458C0.86655 50.1199 0.88447 50.262 0.94591 50.4002H1.93919V51.053Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.403061 53.2813C0.404341 54.2707 1.1557 54.8851 2.12082 54.8864C3.02578 54.8864 3.74642 54.208 3.74642 53.2838C3.7477 52.3571 3.02578 51.6736 2.12338 51.6736C1.15698 51.6723 0.404341 52.2905 0.403061 53.2813ZM0.865141 53.2813C0.865141 52.6029 1.50258 52.3661 2.12338 52.3661C2.7173 52.3661 3.28562 52.6361 3.28434 53.2825C3.28562 53.9251 2.7173 54.1965 2.12082 54.1977C1.50386 54.1977 0.866421 53.9571 0.865141 53.2813Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.402222 56.9124C0.402222 57.9006 1.15358 58.5214 2.11998 58.5201C3.02238 58.5214 3.74558 57.8366 3.7443 56.9124C3.74302 55.9857 3.02494 55.3035 2.11998 55.3035C1.15486 55.3022 0.402222 55.9204 0.402222 56.9124ZM0.861742 56.9124C0.864302 56.234 1.50174 55.996 2.11998 55.9947C2.71646 55.996 3.2835 56.266 3.28222 56.9124C3.28222 57.5563 2.71518 57.8289 2.11998 57.8289C1.50046 57.8289 0.861742 57.5883 0.861742 56.9124Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.68779 59.1112L0.458348 59.1086L0.455788 60.1608C0.455788 61.3038 1.09835 61.9899 2.16331 61.9912C2.94411 61.9899 3.68779 61.5394 3.68779 60.2261V59.1112ZM3.25515 59.7589L3.25643 60.2286C3.25387 60.9096 2.83787 61.2974 2.16843 61.2987C1.26987 61.2974 0.887148 60.8981 0.889708 60.1531L0.888428 59.7576L3.25515 59.7589Z" fill="white"/>
        </svg>


    `,
    logoSecondary: `

        <svg width="180" height="64" viewBox="0 0 180 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71989 1.97876V62.0107H30.3631C48.3778 62.0107 64.3304 52.2328 64.3304 30.2847C64.3304 15.7887 55.0581 1.97876 31.0453 1.97876H7.71989ZM25.8178 13.2223H31.0453C38.9391 13.2223 45.7154 19.4725 45.7154 30.3704C45.7154 42.4639 40.8258 50.7788 30.4501 50.7788H25.8178V13.2223Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M121.296 62.0113H109.2C100.198 51.1287 88.0175 36.8029 82.2741 26.8522H82.0962V62.0113H67.8498V1.97925H80.9916C89.6431 12.6084 102.592 28.2205 106.886 36.3844H107.055V1.97925H121.296V62.0113Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M179.598 26.1274V59.9604C172.307 62.1108 166.217 63.0426 160.728 63.0426C140.743 63.0426 124.285 51.0324 124.285 31.4049C124.285 13.2225 139.286 0.95752 159.611 0.95752C163.552 0.95752 170.675 2.0596 174.96 3.95784L170.498 14.5895C167.586 13.7255 162.267 13.4746 160.555 13.4746C151.975 13.4746 143.142 19.0554 143.142 32.0846C143.142 44.8628 152.67 50.5294 158.838 50.5294C159.443 50.5294 160.38 50.4308 161.501 50.0954V34.5665L179.598 26.1274Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71354 1.92383H0.482821V2.97727C0.481542 4.12031 1.1241 4.80383 2.19034 4.80383C2.97242 4.80511 3.71226 4.35199 3.71354 3.03871V1.92383ZM3.28218 2.57407L3.2809 3.04511C3.27834 3.72735 2.86234 4.11263 2.19418 4.11263C1.29562 4.11135 0.912902 3.70943 0.914179 2.96831V2.57279L3.28218 2.57407Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.424263 6.82497C0.424263 7.81441 1.17562 8.43265 2.14202 8.43265C3.0457 8.43265 3.76762 7.75169 3.76762 6.82625C3.76634 5.90081 3.04698 5.21729 2.1433 5.21729C1.17946 5.21601 0.424263 5.83425 0.424263 6.82497ZM0.885063 6.82497C0.885063 6.14657 1.52506 5.90849 2.1433 5.90849C2.73722 5.90977 3.30554 6.17985 3.30682 6.82497C3.30554 7.47137 2.7385 7.74401 2.14202 7.74401C1.52378 7.74273 0.885063 7.50209 0.885063 6.82497Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71104 9.64947L1.85504 9.64819C1.22144 9.64819 0.87584 9.83251 0.87456 10.3483C0.87456 10.8437 1.29696 11.0395 1.8192 11.0408H3.70848V11.6219H1.70272C0.88224 11.6207 0.422717 11.0946 0.422717 10.3304C0.42144 9.45491 0.85536 8.99795 1.736 9.0005H3.71104V9.64947Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.95412 15.073L0.620364 15.0717C0.506444 14.7261 0.420684 14.3601 0.42195 13.994C0.42195 12.8791 1.16052 12.1738 2.11156 12.1738C2.99476 12.1751 3.76532 12.874 3.76405 14.0106C3.76405 14.3677 3.6514 14.7236 3.51316 14.9348L3.13044 14.7261C3.2226 14.5508 3.30452 14.2794 3.30196 14.0311C3.30452 13.3412 2.83732 12.8637 2.1026 12.8625C1.33716 12.8637 0.882764 13.4103 0.882764 13.9645C0.882764 14.1412 0.900684 14.2833 0.962124 14.4228L1.95412 14.4215V15.073Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7067 15.8694V16.521L0.907343 16.5197L0.908623 17.7984H0.475983L0.477263 15.8682L3.7067 15.8694Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7055 19.1962L0.476063 17.8804V18.4423L1.35798 18.7764L1.35926 19.9181L0.473503 20.2164V20.9434L3.7055 19.7338V19.1962ZM1.75478 18.9479C2.17846 19.0989 2.57398 19.25 3.06806 19.3844V19.3933C2.5727 19.5457 2.17718 19.6826 1.75222 19.8221L1.75478 18.9479Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.14204 22.9375C3.23676 22.7954 3.32508 22.6047 3.32508 22.4166C3.32636 22.1426 3.17532 21.9954 2.98332 21.9967C2.77212 21.9967 2.61212 22.2207 2.30748 22.5983C1.96444 23.0335 1.6918 23.2626 1.29372 23.2626C0.887964 23.2626 0.416924 22.8761 0.416924 22.1452C0.418204 21.8098 0.519324 21.4399 0.742044 21.198L1.1094 21.4335C0.985244 21.6038 0.850844 21.8022 0.852124 22.0927C0.852124 22.3602 0.996764 22.5932 1.30012 22.5945C1.6534 22.5945 1.88892 22.1631 2.09244 21.9033C2.34844 21.591 2.54556 21.3286 2.97692 21.3298C3.41084 21.3311 3.759 21.765 3.76028 22.3513C3.759 22.7212 3.62716 23.0143 3.47868 23.1666L3.14204 22.9375Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.471489 28.0579V27.5882C1.21517 26.9686 1.95373 26.353 2.78701 25.7462V25.7386L0.471489 25.736L0.472769 25.2163L3.70349 25.2176V25.6925C2.96621 26.3094 2.23021 26.92 1.39949 27.528V27.537L3.70093 27.5382L3.70349 28.0592L0.471489 28.0579Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.26803 30.5569V29.474L2.38099 29.4727L2.38227 30.5326L1.94707 30.5313V29.474L0.903868 29.4727L0.902588 30.7515L0.469948 30.7502V28.8225L3.70195 28.8238L3.69939 30.5569H3.26803Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69953 33.5331C2.86881 33.7903 2.04321 34.0515 1.10242 34.3049L1.10114 34.3126C2.05473 34.5302 2.90081 34.7529 3.74561 34.9731L3.65089 35.503L0.466255 34.5955L0.468815 33.9311C1.23425 33.6931 2.00481 33.4511 2.88417 33.2182V33.2092C2.00481 32.9775 1.23425 32.7484 0.468815 32.5142V31.8435L3.65601 30.9487L3.74817 31.5887C2.90209 31.8051 2.05473 32.015 1.1037 32.2262V32.2351C2.04449 32.4899 2.87137 32.7471 3.69953 33.0057V33.5331Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.466499 39.5606V38.9116L2.98682 38.9129V38.9039C2.0857 38.5468 1.2729 38.1782 0.466499 37.8159V37.4639C1.2729 37.1183 2.0857 36.7715 2.9881 36.4335V36.4259L0.466499 36.4233V35.9023L3.6985 35.9036L3.69722 36.7049C2.90618 37.0364 1.63898 37.5702 1.22298 37.6956V37.7046C1.6569 37.8569 2.9049 38.4406 3.69594 38.8067V39.5606H0.466499Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69661 41.2636L0.465895 39.9478V40.5097L1.34781 40.8425L1.34909 41.9842L0.464615 42.2825V43.0121L3.69661 41.7999V41.2636ZM1.74205 41.0127C2.16957 41.165 2.56381 41.3174 3.05661 41.4518V41.4594C2.56253 41.6118 2.16829 41.7513 1.74333 41.8882L1.74205 41.0127Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.461685 46.2419L0.462965 45.7721C1.20665 45.1526 1.94393 44.5356 2.77976 43.9263V43.9212L0.462965 43.9199L0.464245 43.3977L3.69624 43.399V43.8739C2.95768 44.4908 2.22168 45.1052 1.38841 45.7119V45.7196L3.69368 45.7209V46.2431L0.461685 46.2419Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.93919 51.053H0.60543C0.49151 50.7061 0.40447 50.3388 0.40703 49.9727C0.40575 48.8578 1.14303 48.1525 2.09535 48.1538C2.97855 48.1538 3.74911 48.854 3.74784 49.9906C3.74911 50.3465 3.63647 50.701 3.49823 50.9148L3.11551 50.7061C3.20767 50.5295 3.28831 50.2594 3.28703 50.0085C3.28575 49.3199 2.81983 48.8424 2.08767 48.8424C1.31967 48.8424 0.86783 49.3865 0.86783 49.9458C0.86655 50.1199 0.88447 50.262 0.94591 50.4002H1.93919V51.053Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.403061 53.2813C0.404341 54.2707 1.1557 54.8851 2.12082 54.8864C3.02578 54.8864 3.74642 54.208 3.74642 53.2838C3.7477 52.3571 3.02578 51.6736 2.12338 51.6736C1.15698 51.6723 0.404341 52.2905 0.403061 53.2813ZM0.865141 53.2813C0.865141 52.6029 1.50258 52.3661 2.12338 52.3661C2.7173 52.3661 3.28562 52.6361 3.28434 53.2825C3.28562 53.9251 2.7173 54.1965 2.12082 54.1977C1.50386 54.1977 0.866421 53.9571 0.865141 53.2813Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.402222 56.9124C0.402222 57.9006 1.15358 58.5214 2.11998 58.5201C3.02238 58.5214 3.74558 57.8366 3.7443 56.9124C3.74302 55.9857 3.02494 55.3035 2.11998 55.3035C1.15486 55.3022 0.402222 55.9204 0.402222 56.9124ZM0.861742 56.9124C0.864302 56.234 1.50174 55.996 2.11998 55.9947C2.71646 55.996 3.2835 56.266 3.28222 56.9124C3.28222 57.5563 2.71518 57.8289 2.11998 57.8289C1.50046 57.8289 0.861742 57.5883 0.861742 56.9124Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.68779 59.1112L0.458348 59.1086L0.455788 60.1608C0.455788 61.3038 1.09835 61.9899 2.16331 61.9912C2.94411 61.9899 3.68779 61.5394 3.68779 60.2261V59.1112ZM3.25515 59.7589L3.25643 60.2286C3.25387 60.9096 2.83787 61.2974 2.16843 61.2987C1.26987 61.2974 0.887148 60.8981 0.889708 60.1531L0.888428 59.7576L3.25515 59.7589Z" fill="white"/>
        </svg>

    `,

    // Subthemes.  The values within a subtheme will overlay a copy of the
    // parent theme.  Subthemes can themselves contain subthemes.
    subthemes: {
        siteby: {
            tweak: 0.075, // Align baseline of font so SiteByStarberry lines up.  This needs to be kludged for each particular site. :(
        },
        content: {
            props: {
                direction: "column",
            },
            contained: true,
        },
        header: {
            contained: true,

            props: {
                direction: "row",
                alignItems: "middle",
                justify: "space-between",
                height: "5rem",
                paddingTop: "1rem",
            },

            sx: {
                root: {
                    backgroundColor: "black",
                },
            },

            subthemes: {
                logo: {
                    props: {
                        width: "10rem",
                        justify: "left",
                        sx: {},
                    },
                },

                menu: {
                    type: "burger",
                    props: {
                        direction: "row",
                        alignItems: "left",
                        justify: "space-around",
                    },

                    anchorOrigin: { horizontal: "center" },
                    transformOrigin: { horizontal: "center" },

                    sx: {
                        root: {
                            "& .HeaderMenuContainer": {
                                display: "flex",
                                "flex-direction": "row",
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink": {
                                // "flex-direction": "row"
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink span": {
                                "font-weight": 600,
                            },
                            "& .HeaderMenuLevel1 span": {},
                        },
                    },

                    menus: {
                        Home: "/",
                        "About Us": "/about-us",
                        Careers: "/careers",
                        "‍Work": "/our-work",
                        Stories: "/stories",
                        "Contact Us": "/contact-us",
                        "Who We Work With": {
                            "Estate Agents": "/who-we-work-with/estate-agents",
                            "Property Developers":
                                "/who-we-work-with/property-developers",
                            Proptech: "/who-we-work-with/proptech",
                            Finance: "/who-we-work-with/finance",
                            "Solicitors & Lawyers": "/who-we-work-with/legal",
                            Architects: "/who-we-work-with/architects",
                            "Interior Design":
                                "/who-we-work-with/interior-design",
                        },
                        "What We Do": {
                            Branding: "/what-we-do/branding",
                            "Website Design": "/what-we-do/website-design",
                            "Website Development":
                                "/what-we-do/website-development",
                            "Content Marketing":
                                "/what-we-do/content-marketing",
                            Video: "/what-we-do/video",
                            "Social Media": "/what-we-do/social-media",
                            SEO: "/what-we-do/seo",
                            "Digital Advertising":
                                "/what-we-do/digital-advertising",
                            "Email Marketing": "/what-we-do/email-marketing",
                        },
                    },
                },
            },
        },
        footer: {
            contained: "outer",
            props: {
                direction: "column",
            },

            sx: {
                root: {
                    "& a": {
                        color: "currentColor",
                    },
                    "& ul": {
                        margin: 0,
                        padding: 0,
                        "list-style": "none",
                        display: "block",
                    },
                },
            },

            titleVariant: "button",
            colors: {
                background: "primary",
                text: "#fff",
            },
            menu: {
                menus: {
                    Menu: {
                        Home: "/",
                        "About Us": "/about-us",
                        Careers: "/careers",
                        "‍Work": "/our-work",
                        Stories: "/stories",
                        "Contact Us": "/contact-us",
                    },
                    "Who We Work With": {
                        "Estate Agents": "/who-we-work-with/estate-agents",
                        "Property Developers":
                            "/who-we-work-with/property-developers",
                        Proptech: "/who-we-work-with/proptech",
                        Finance: "/who-we-work-with/finance",
                        "Solicitors & Lawyers": "/who-we-work-with/legal",
                        Architects: "/who-we-work-with/architects",
                        "Interior Design": "/who-we-work-with/interior-design",
                    },
                    "What We Do": {
                        Branding: "/what-we-do/branding",
                        "Website Design": "/what-we-do/website-design",
                        "Website Development":
                            "/what-we-do/website-development",
                        "Content Marketing": "/what-we-do/content-marketing",
                        Video: "/what-we-do/video",
                        "Social Media": "/what-we-do/social-media",
                        SEO: "/what-we-do/seo",
                        "Digital Advertising":
                            "/what-we-do/digital-advertising",
                        "Email Marketing": "/what-we-do/email-marketing",
                    },
                    "Follow Us": {
                        Instagram: "https://www.instagram.com/starberrylife/",
                        Twitter: "https://twitter.com/starberry",
                        YouTube:
                            "https://www.youtube.com/channel/UCAPHSlQISzaD-384O__9QhQ",
                        LinkedIn: "https://www.linkedin.com/company/starberry",
                    },
                },
            },
        },
        menuRouterHomePage: {
            // Specify which homepage version layout we want to use
            props: {
                pageVariant: MYACCOUNT_LIGHT ? "MyProperty" : "Dashboard",
            },
        },
        // "myAccount": {
        // "subthemes": {
        accordionComp: {
            props: {
                data: {
                    introText: `<a href="https://ggfx-dng.s3.eu-west-2.amazonaws.com/i.prod/My_DNG_Applicant_User_Guide_Final_0df2315163.pdf" style="color:#c8102e;" download="My_DNG_Applicant_User_Guide.pdf" target="_blank">Download User Guide</a>`,
                    accordion: [
                        {
                            title: "Why am I not receiving email notifications of revised offers from other bidders?",
                            description: [
                                "You must have your email notification preferences set to 'On' in the Profile section of your MyDNG account in order to receive email updates. Please check this in your MyDNG, and if the problem persists please contact your agent directly. <br /> <img src='https://ggfx-dng.s3.eu-west-2.amazonaws.com/i.prod/email_notification_preferences_3935887040.jpg' />",
                            ],
                        },
                        {
                            title: "How do I create a property alert so I receive details of new listings that match my requirements in MyDNG?",
                            description: [
                                "Click on My Property on the left hand menu. Click on Saved Searches and create a new search by entering your requirements (location / No. beds / Price etc). All your saved searches created show in Saved Searches.",
                            ],
                        },
                        {
                            title: "How do I place an offer on a property through MyDNG?",
                            description: [
                                "Click on My Property on the left hand menu. Any properties that you have viewed will be displayed. Click on the property you wish to place an offer on.",
                                "In order to make an offer you will have to submit your Proof of Funds to the agent handling the sale. To do this click on ‘Request to Bid’ on the right hand side of the screen.",
                                "Upload your Proof of Funds document and click Submit. PDF’s , Word Documents and jpeg are all file types supported by the uploader.",
                                "Only one document can be uploaded as Proof of Funds. If you need to submit more than one document as Proof of Funds, please email the documents directly to the negotiator handling the sale for review.",
                                "Once the negotiator accepts your proof of funds documents you will be in a position to place an offer via MyDNG.  On the property record in MyDNG the ‘Request to Bid’ button will change to  ‘Place Bid’.",
                                "Click ‘Place Bid’ and enter the amount of your offer and click submit.",
                                "Please Note - If you make an error typing your bid amount please contact the negotiator handling the sale by email/telephone as soon as possible and they can correct the bid amount in the system.  Please note - MyDNG will not allow you to place a revised bid lower than your previous bid you will need to contact the negotiator handling the sale directly for assistance.",
                                "When making a higher offer through MyDNG, the minimum increase to your offer is €500.00.  E.g. If your previous offer was €325,000 then the next offer you can make is a minimum of €325,500",
                            ],
                        },
                        {
                            title: "What if I change my mind about an offer I previously made?",
                            description: [
                                "The option is there to withdraw your offer via MyDNG. Click Withdrawn next to your offer. If you withdraw your offer you will be deemed out of the bidding process and will lose sight of the bidding history on that property in your MyDNG account.  It is recommended that if you are simply pausing your bidding / do not want to increase your current offer at the present time, do not change your bid to withdrawn, leave it as ‘Bid Pending’. If you are out of the process completely then withdraw the bid.",
                            ],
                        },
                    ],
                    status: [
                        {
                            status: "Bid Under Review",
                            meaning:
                                "Used when a bidder makes their first offer through MyDNG. The agent handling the sale will review the bid and assuming everything is in order, the agent will change the status to Bid Pending and record it as an offer against the property.",
                        },
                        {
                            status: "Bid Pending",
                            meaning:
                                "The offer has been made via MyDNG and has been received by the agent handling the sale and recorded against the property record for consideration by the vendor.",
                        },
                        {
                            status: "Bid Rejected",
                            meaning:
                                "The offer has been put to the vendor, who in turn has rejected it.",
                        },
                        {
                            status: "Bid Withdrawn",
                            meaning:
                                "The bidder has changed their mind regarding the offer they made and has withdrawn the offer.",
                        },
                        {
                            status: "Bid Accepted",
                            meaning:
                                "The Vendor has accepted the offer and the property is Sale Agreed to the bidder making that offer.",
                        },
                    ],
                },
            },
        }, // options src/core/components/MyAccount/accordion/defaults.js
        bookingWidget: {
            // options: src/core/components/MyAccount/property/components/iframeBookViewing/defaults.js
        },
        imageBox: {
            // options: src/components/MyAccount/imageBox/defaults.js
        },
        videoText: {
            // options: src/components/MyAccount/modules/videoText/defaults.js
        },
        iconBox: {
            // options: src/components/MyAccount/iconBox/defaults.js
        },
        static2column: {
            // options: src/components/MyAccount/modules/grid/defaults.js
        },
        bgIcon: {
            // options: src/components/MyAccount/modules/bgIcon/defaults.js
        },
        socialShare: {
            // options: src/components/MyAccount/socialShare/defaults.js
        },
        backButton: {
            // options: src/components/MyAccount/backButton/defaults.js
        },
        projectIcons: {
            // options: src/components/MyAccount/icons/defaults.js
            props: {
                // "propBedsIcon": CustomBedsIcon,
                // "propBathIcon": CustomBathIcon,
                // "propRecIcon": CustomRecIcon,
                logoIcon: CustomLogoIcon,
            },
        },
        sidebarDrawer: {
            // options: src/components/MyAccount/SidebarDrawerDefaults.js
            props: {
                menus: [
                    {
                        hide: false,
                        name: "Back to Website",
                        icon: {
                            name: "backToWebsiteIcon",
                            props: {
                                color: "primary",
                                fontSize: "small",
                            },
                        },
                        link: `${WEBSITE_URL}`,
                        newtab: true,
                        menutype: "external",
                    },
                    {
                        hide: false,
                        name: "Dashboard",
                        icon: {
                            // "name": "Home", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                            name: "dashboardIcon",
                            props: {
                                color: "primary",
                                fontSize: "small",
                            },
                        },
                        link: `${MYACCOUNT_DASHBOARD}`,
                    },
                    {
                        hide: false,
                        name: "My Property",
                        icon: {
                            name: "myPropertyIcon",
                        },
                        link: `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        hide: false,
                        name: "Inbox",
                        icon: {
                            name: "inboxIcon",
                        },
                        link: `${MYACCOUNT_INBOX}`,
                    },
                    {
                        hide: false,
                        name: "Calendar",
                        icon: {
                            name: "calendarIcon",
                        },
                        link: `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        hide: false,
                        name: "Profile",
                        icon: {
                            name: "profileIcon",
                        },
                        link: `${MYACCOUNT_PROFILE}`,
                    },
                    {
                        hide: false,
                        name: "FAQ",
                        icon: {
                            name: "faqIcon",
                        },
                        link: `${MYACCOUNT_FAQ}`,
                    },
                    {
                        hide: false,
                        name: "Log out",
                        icon: {
                            name: "logoutIcon",
                        },
                        link: "#",
                        funcName: "logout",
                    },
                ],
                menuListItem: {
                    button: true,
                    disableRipple: true,
                    // "divider": true,
                },
            },
            sx: theme => ({
                "& .sidebarDrawer-drawerCommon-root": {
                    display: "flex",
                    minHeight: "100vh",
                },
                "& .sidebarDrawer-drawerCommon-drawer": {
                    [theme.breakpoints.up("md")]: {
                        width: `${theme.myAccountSetDrawerWidth}px`,
                        flexShrink: 0,
                    },
                },
                "& .sidebarDrawer-drawerCommon-drawerPaper": {
                    width: `${theme.myAccountSetDrawerWidth}px`,
                    backgroundColor: "#1B1434",
                    color: theme.palette.primary.contrastText,
                    padding: "50px 50px 0 50px",
                },
                "& .sidebarDrawer-menuList": {
                    marginLeft: theme.spacing(3),
                    padding: theme.spacing(0),
                    // [theme.breakpoints.up('lg')]: {
                    //     "padding": theme.spacing(0, 8),
                    // },
                    "& .sidebarDrawer-menuListItem": {
                        padding: theme.spacing(1.5, 0),
                        opacity: 0.4,
                        background: "none",
                        "&.Mui-selected": {
                            opacity: 1,
                            background: "none",
                        },
                        "&.Mui-selected:hover": {
                            opacity: 1,
                            background: "none",
                        },
                        "&:hover": {
                            opacity: 1,
                            background: "none",
                        },
                        "& .sidebarDrawer-menuListItemText": {},
                        "& .sidebarDrawer-menuListItemTextPrimary": {},
                    },
                    "& .sidebarDrawer-menuListItemIcon": {
                        minWidth: "40px",
                        color: theme.palette.primary.contrastText,
                    },
                },
            }),
            subthemes: {
                logo: {
                    sx: theme => ({
                        margin: theme.spacing(4, "auto", 2, "auto"),
                        width: "94px",
                        height: "auto",
                        [theme.breakpoints.up("md")]: {
                            // "width": "94px",
                            margin: theme.spacing(5, "auto", 9, "auto"),
                        },
                    }),
                },
            },
        },
        appBar: {
            // options: src/components/MyAccount/AppBarDefaults.js
            props: {
                burgerButton: {
                    color: "primary",
                    "aria-label": "open drawer",
                    edge: "start",
                    disableRipple: true,
                    sx: {
                        py: 0,
                        marginRight: "0!important",
                        "&:hover": {
                            background: "transparent",
                        },
                        "& svg": {
                            width: "33px",
                            height: "24px",
                        },
                    },
                },
                menusBottomFixed: [
                    {
                        id: 1,
                        hide: false,
                        name: "Dashboard",
                        icon: {
                            name: "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                            props: {
                                color: "primary",
                                fontSize: "small",
                            },
                        },
                        link: `${MYACCOUNT_DASHBOARD}`,
                    },
                    {
                        id: 2,
                        hide: false,
                        name: "My Property",
                        icon: {
                            name: "myPropertyIcon",
                        },
                        link: `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        id: 3,
                        hide: false,
                        name: "Inbox",
                        icon: {
                            name: "inboxIcon",
                        },
                        link: `${MYACCOUNT_INBOX}`,
                    },
                    {
                        id: 4,
                        hide: false,
                        name: "Calendar",
                        icon: {
                            name: "calendarIcon",
                        },
                        link: `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        id: 5,
                        hide: true,
                        name: "Search",
                        icon: {
                            name: "searchIcon",
                        },
                        link: `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        id: 7,
                        hide: false,
                        name: "FAQ",
                        icon: {
                            name: "faqIcon",
                        },
                        link: `${MYACCOUNT_FAQ}`,
                    },
                    {
                        id: 6,
                        hide: false,
                        name: "Menu",
                        icon: {
                            name: "mobileBurgerIcon",
                        },
                        link: "#",
                        funcName: "menu",
                    },
                ],
            },
            sx: theme => ({
                "& .appBar": {
                    boxShadow: "none",
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    [theme.breakpoints.up("md")]: {
                        width: `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                        marginLeft: `${theme.myAccountSetDrawerWidth}px`,
                        // "marginRight": `${theme.myAccountSetDrawerWidth}px`, // Enable this if Drawer is positioned right
                    },
                },
                "& .appBar-grow": {
                    [theme.breakpoints.up("md")]: {
                        flexGrow: 1,
                    },
                },
                "& .appBar-sectionDesktop": {
                    display: "none",
                    [theme.breakpoints.up("md")]: {
                        display: "flex",
                        alignItems: "center",
                    },
                },
                "& .appBar-sectionMobile": {
                    display: "flex",
                    [theme.breakpoints.up("md")]: {
                        display: "none",
                    },
                },
                "& li.MuiButtonBase-root": {
                    "& > button": {
                        // This should be the icon in the dropdown list
                        marginRight: theme.spacing(1),
                        padding: theme.spacing(1, 0.2),
                    },
                    "& > .appBar-notification": {
                        // This should be the icon in the dropdown list
                        marginRight: theme.spacing(1),
                    },
                    "& > a": {
                        flexGrow: 1,
                    },
                },
                "& .appBar-notification": {
                    color: "rgba(0, 0, 0, 0.54)",
                    "& .appBar-badge": {
                        color: "#fff",
                        height: "23px",
                        minWidth: "24px",
                        borderRadius: "100%",
                        border: "2px solid #fff",
                        backgroundColor: theme.palette.primary.dark,
                    },
                },
                "& .appBar-burgerButton": {
                    marginRight: theme.spacing(2),
                    [theme.breakpoints.up("md")]: {
                        display: "none",
                    },
                },
                "& .appBar-appBarTitle": {
                    marginLeft: "auto",
                    marginRight: "auto",
                    [theme.breakpoints.up("md")]: {
                        display: "none",
                    },
                },
                "& .appBar-menuListItemMobile": {
                    justifyContent: "center",
                    opacity: 0.4,
                    background: "none",
                    "&.Mui-selected": {
                        opacity: 1,
                        background: "none",
                    },
                    "& .appBar-menuListItemMobile-divider": {
                        borderColor: "black",
                    },
                },
                "& .appBar-menuListItemIconMobile": {
                    color: theme.palette.primary.contrastText,
                    minWidth: "inherit",
                },
                "& .appBar-userDropdown": {
                    fontFamily: theme.secondaryFontFamily,
                    margin: theme.spacing(0, 3.25),
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    color: theme.palette.grey.grey1,
                    "&:hover": {
                        background: "none",
                    },
                    "& .appBar-label": {
                        textTransform: "capitalize",
                        fontSize: theme.bodyMediumSize,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.grey.grey1,
                    },
                    "& .appBar-text": {
                        "&:hover": {
                            backgroundColor: "transparent",
                            color: theme.palette.secondary.main,
                        },
                    },
                },
                "& .appBar-appBarMobile": {
                    boxShadow: "none",
                    backgroundColor: theme.palette.primary.dark,
                    border: "none",
                    width: "100%",
                    top: "auto",
                    bottom: 0,
                },
                "& .appBar-userAvatar": {
                    textTransform: "uppercase",
                },
            }),
        },
        customSidebarDrawer: {
            // options: src/components/MyAccount/customSidebarDrawer/defaults.js
            subthemes: {
                menuListIconsTabs: {
                    sx: theme => ({
                        "& .menuListIconsTabs": {
                            padding: 0,
                            margin: 0,
                        },
                        "& .menuListIconsTabs-vertical": {},
                        "& .menuListIconsTabs-indicator": {
                            display: "none",
                        },
                        "& .menuListIconsTabs-tab": {
                            fontWeight: "bold",
                            textTransform: "none",
                            minHeight: "58px",
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            paddingTop: theme.spacing(1.5),
                            paddingBottom: theme.spacing(1.5),
                            paddingLeft: theme.gridItemPmobile,
                            paddingRight: theme.gridItemPmobile,
                            opacity: 1,
                            [theme.breakpoints.up("sm")]: {
                                fontSize: theme.bodyMedium,
                                lineHeight: theme.lineHeightBMedium,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up("md")]: {
                                padding: theme.spacing(1.5, 0),
                            },
                            "&.menuListIconsTabs-tab-wrapper": {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                            },
                        },
                        "& .menuListIconsTabs-tab-labelIcon svg": {
                            color: theme.palette.text.primary,
                            marginBottom: 0,
                            marginRight: theme.spacing(2.25),
                        },
                    }),
                },
            },
        },
        globalComponentLayout: {
            sx: theme => ({
                marginBottom: theme.spacing(2.6),
                [theme.breakpoints.up("sm")]: {
                    marginBottom: theme.spacing(6),
                },
                [theme.breakpoints.up("lg")]: {
                    marginBottom: theme.spacing(7),
                },
                "& .globalComponentLayout-titleContainer": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    minHeight: 56,
                    paddingBottom: theme.spacing(2),
                    [theme.breakpoints.up("sm")]: {
                        paddingBottom: theme.spacing(3),
                    },
                },
                "& .globalComponentLayout-title": {
                    fontFamily: theme.primaryFontFamily,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.grey.grey6,
                    fontSize: theme.h6,
                    [theme.breakpoints.up("sm")]: {
                        fontSize: theme.h5,
                        lineHeight: theme.h5LineH,
                        color: theme.palette.text.primary,
                    },
                    "& span": {
                        fontWeight: 400,
                        fontSize: "16px",
                        marginTop: "10px",
                        marginLeft: "10px",
                    },
                },
                "& .globalComponentLayout-titleLinkMore": {
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.grey.grey2,
                    marginLeft: "auto",
                    [theme.breakpoints.up("sm")]: {
                        fontSize: theme.bodyMedium,
                        lineHeight: theme.lineHeightBMedium,
                        fontWeight: "normal",
                        color: theme.palette.text.primary,
                    },
                },
            }),
        },
        globalDropdownActions: {
            // options: src/components/MyAccount/property/savedSearch/defaultsActions.js
        },
        globalPageTitle: {
            // options: src/components/MyAccount/pageTitle/defaults.js
            props: {
                pageTitleProps: {
                    component: "h1",
                    variant: "h3",
                },
            },
        },
        globalForm: {
            // options: src/components/MyAccount/forms/defaults.js
            props: {
                defaultCountry: defaultCountry,
                currencySymbol: currencySymbol,
                textFieldProps: {
                    // "variant": "outlined",
                },
                radioGroupProps: {
                    row: true,
                },
                selectFieldProps: {
                    // "variant": "outlined",
                },
                fieldsTitle: {
                    component: "h2",
                    variant: "h5",
                },
                datetimeSlotProps: {
                    showBtnSuitableTime: false,
                },
                formButton: {
                    variant: "contained",
                    color: "primary",
                    // "fullWidth": true,
                },
                _data: {
                    submitLabel: "Submit",
                },
            },
            // "sx": (theme) => ({
            //     '& .multi-select-input': {
            //         "zIndex": "9999"
            //     },
            //     "& .loqate-options": {
            //         // "zIndex": "9999"
            //         "padding": "2px"
            //     },
            //     "& .loqate-option": {
            //         "zIndex": "9999"
            //     },
            //     "& .globalForm-globalFormForm": {
            //     },
            //     "& .globalForm-fieldContainer": {
            //         "display": "flex",
            //         "flexDirection": "column",
            //     },
            //     "& .globalForm-textField": {
            //         "width": "100%",
            //         "marginBottom": theme.spacing(3),
            //     },
            //     "& .globalForm-selectField": {
            //         "width": "100%",
            //         "marginBottom": theme.spacing(3),
            //     },
            //     "& .globalForm-jsonAutocompleteField": {
            //         "width": "100%",
            //         "marginBottom": theme.spacing(3),
            //         // "transform": "translate(14px, 9px) scale(1)"
            //     },
            //     "& .myaccount-date-time-calendar": {
            //         "display": "flex",
            //         "flexDirection": "column",
            //         "alignItems": "center",
            //         "& .react-datepicker": {
            //             // "width": "100%",
            //             "marginBottom": theme.spacing(3),
            //             "& .react-datepicker__month-container": {
            //                 "@media (max-width: 400px)": {
            //                     "width": "100%",
            //                 },
            //             },
            //             "& .react-datepicker__navigation--next": {
            //                 "@media (max-width: 400px)": {
            //                     "right": "2px",
            //                 },
            //             },
            //             "& .react-datepicker__time-container": {
            //                 "@media (max-width: 400px)": {
            //                     "width": "100%",
            //                     "border": "none",
            //                 },
            //                 "& .react-datepicker__header.react-datepicker__header--time": {
            //                     "@media (min-width: 401px)": {
            //                         "display": "flex",
            //                         "minHeight": "62px",
            //                         "alignItems": "center",
            //                         "justifyContent": "center",
            //                     },
            //                 },
            //                 "& .react-datepicker__time-box": {
            //                     "@media (max-width: 400px)": {
            //                         "width": "100%",
            //                     },
            //                 },
            //                 "& li.react-datepicker__time-list-item": {
            //                     "display": "flex",
            //                     "alignItems": "center",
            //                     "&.react-datepicker__time-list-item--disabled": {
            //                         "display": "none",
            //                     },
            //                 },
            //                 "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
            //                     "backgroundColor": theme.palette.primary.main,
            //                 },
            //                 "& .react-datepicker__day--selected": {
            //                     "backgroundColor": theme.palette.primary.main,
            //                     "color": "#fff",
            //                     "&:hover": {
            //                         "backgroundColor": theme.palette.primary.main,
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     "& .suggestionsContainer": {
            //         "position": "relative",
            //         "& .suggestionsContainerOpen": {
            //             "position": "absolute",
            //             "zIndex": 998,
            //             "top": "50px",
            //             "left": 0,
            //             "right": 0,
            //         },
            //         "& .suggestionsList": {
            //             "margin": 0,
            //             "padding": 0,
            //             "listStyleType": "none",
            //         },
            //         "& .suggestion": {
            //             "display": "block",
            //             "padding": "10px 15px",
            //             "&:hover": {
            //                 "cursor": "pointer",
            //                 "background": theme.palette.grey.grey3,
            //             },
            //         },
            //     },
            //     "& .globalForm-fieldsRadio": {
            //         "marginBottom": theme.spacing(2),
            //         "marginTop": "0px",
            //         "& + .css-11906e0-MuiStack-root ": {
            //             "marginTop": "0px"
            //         }
            //     },
            //     "& .globalForm-actionContainer": {
            //     },
            //     "& .globalForm-fieldsTitle": {
            //         "marginBottom": theme.spacing(3),
            //     },
            //     "& .globalForm-formButton": {

            //     },
            //     "& .phonenumber_container": {
            //         // "background": 'red',
            //         "& .PhoneInputCountryIcon": {
            //             "width": "40px",
            //             "position": "absolute",
            //             "height": "40px",
            //             "& img,svg": {
            //                 "marginTop": "12px",
            //                 "paddingLeft": "10px",
            //                 "width": "100%"
            //             }
            //         },
            //         "& .PhoneInputCountrySelect": {
            //             "width": "40px",
            //             "position": "absolute",
            //             "opacity": "0",
            //             "zIndex": "2",
            //             "height": "40px",
            //             "cursor": "pointer",
            //         },
            //         "& .MuiFormControl-root": {
            //             "& input": {
            //                 "paddingLeft": "50px"
            //             }
            //         }
            //     },
            // }),
        },
        mainContent: {
            // options: src/components/MyAccount/ContentDefaults.js
        },
        alertComponent: {
            // options: src/components/MyAccount/alert/defaults.js
        },
        welcomeCard: {
            // options: src/components/MyAccount/user/welcome/defaults.js
            props: {
                cardProps: {
                    // "variant": "outlined",
                    elevation: 0,
                },
            },
            sx: theme => ({
                marginBottom: theme.spacing(3.75),
                [theme.breakpoints.up("sm")]: {
                    marginBottom: theme.spacing(4),
                },
                [theme.breakpoints.up("lg")]: {
                    marginBottom: theme.spacing(8),
                },
                "& .welcomeCard-cardHeader": {
                    padding: 0,
                    "& .welcomeCard-avatar": {
                        marginRight: theme.spacing(2.5),
                        [theme.breakpoints.up("sm")]: {
                            marginRight: theme.spacing(3.75),
                        },
                        [theme.breakpoints.up("lg")]: {
                            display: "none",
                        },
                    },
                    "& .welcomeCard-title": {
                        fontSize: theme.h5,
                        lineHeight: theme.h5LineHm,
                        fontWeight: "normal",
                        textTransform: "capitalize",
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.h3,
                            lineHeight: theme.h3LineHm,
                        },
                    },
                    "& .welcomeCard-subheader": {
                        "& a": {
                            color: theme.palette.primary.dark,
                            fontSize: "1.125rem",
                            lineHeight: "1.685rem",
                            fontWeight: 400,
                        },
                    },
                },
                "& .welcomeCard-cardHeaderCaption": {
                    color: theme.palette.grey.grey2,
                    [theme.breakpoints.up("lg")]: {
                        display: "none",
                    },
                },
                "& .welcomeCard-welcomeAvatar": {
                    display: "none",
                    // "margin": 0,
                    // // "backgroundColor": "#ff0000",
                    // "width": theme.spacing(10),
                    // "height": theme.spacing(10),
                },
                "& .welcomeCard-welcomeAvatar-img": {
                    // "margin": 0,
                    // "backgroundColor": "#ff0000",
                    width: theme.spacing(10),
                    height: theme.spacing(10),
                },
            }),
        },
        circularProgress: {
            // options: src/components/MyAccount/progress/defaults.js
        },
        noActions: {
            // options: src/components/MyAccount/progress/NoActionsDefaults.js
        },
        formTextField: {},
        formSwitchField: {
            // options: src/components/MyAccount/forms/FormSwitchField/defaults.js
        },
        loginSignup: {
            // options: src/components/MyAccount/modals/defaults.js
            props: {
                logoType: "logoSecondary",
                showMarketingConsent: false,
            },
        },
        backToWebsite: {
            // options: src/components/MyAccount/modals/backToWebsite/defaults.js
        },
        logoLogin: {
            // options: src/components/MyAccount/Logo/defaults.js
            sx: theme => ({
                "& .dialogLogo": {
                    textAlign: "center",
                    "padding-top": theme.spacing(5),
                    "padding-bottom": theme.spacing(1),
                    [theme.breakpoints.up("sm")]: {
                        "padding-top": theme.spacing(8),
                    },
                    "& svg": {
                        width: "auto",
                        height: "auto",
                        "max-height": "40px",
                        [theme.breakpoints.up("sm")]: {
                            "max-height": "50px",
                        },
                        [theme.breakpoints.up("md")]: {
                            "max-height": "80px",
                        },
                        "& path": {
                            fill: theme?.palette?.icon?.loginLogo || "",
                        },
                    },
                },
            }),
        },
        actionCardContainer: {
            // Wrapper around action cards component
        },
        actionCard: {
            // options: src/components/MyAccount/actions/action/defaults.js - actionCardContainer/actionCard
            props: {
                cardAction: theme => ({
                    color: theme.palette.text.primary,
                }),
            },
            sx: theme => ({
                "&.actionCard-card": {
                    marginBottom: 0,
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    "&.disabled": {
                        opacity: 0.5,
                    },
                    "&.other-bidder": {
                        opacity: 0.5,
                    },
                },
                "& .actionCard-cardActionButton": {
                    backgroundColor: theme.palette.grey.grey4,
                    color: theme.palette.primary.dark,
                },
                "& .actionCard-cardHeader": {
                    padding: theme.spacing(1, 0),
                    [theme.breakpoints.up("sm")]: {
                        padding: theme.spacing(1.5, 0),
                    },
                    [theme.breakpoints.down("lg")]: {
                        "&.hasActionsButtons": {
                            flexWrap: "wrap",
                            "& .MuiCardHeader-content": {
                                flex: "50%",
                            },
                            "& .cardHeader-action": {
                                flex: "50%",
                                marginLeft: "76px",
                                marginTop: "0px",
                                "& button": {
                                    width: "auto",
                                },
                            },
                        },
                    },
                    [theme.breakpoints.down("sm")]: {
                        "&.hasActionsButtons": {
                            "& .cardHeader-action": {
                                marginLeft: "63px",
                            },
                        },
                    },
                    "& .cardHeader-avatar": {
                        marginLeft: "auto",
                        marginRight: theme.spacing(1.875),
                        [theme.breakpoints.up("sm")]: {
                            marginRight: theme.spacing(3.5),
                        },
                    },
                    "& .cardHeader-title": {
                        fontFamily: theme.secondaryFontFamily,
                        color: theme.palette.grey.grey1,
                        fontSize: theme.bodySmall,
                        marginBottom: theme.spacing(0.25),
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.bodyLarge,
                            lineHeight: theme.lineHeightBLarge,
                            marginBottom: theme.spacing(0.5),
                        },
                        [theme.breakpoints.up("md")]: {
                            fontSize: theme.bodySmallLarge,
                            lineHeight: theme.lineHeightBSLarge,
                            marginBottom: theme.spacing(0.5),
                        },
                    },
                    "& .cardHeader-subheader": {
                        color: theme.palette.grey.grey2,
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.bodyMedium,
                            lineHeight: theme.lineHeightBMedium,
                        },
                    },
                    "& .cardHeader-action": {
                        alignSelf: "center",
                        marginRight: theme.spacing(0.5),
                    },
                    "& .cardWithAction": {
                        "& button": {
                            background: "transparent",
                            color: theme.palette.secondary.main,
                            fontWeight: "normal",
                            border: 0,
                            margin: 0,
                            padding: 0,
                            boxShadow: "none",
                            textTransform: "initial",
                            textDecoration: "underline",
                            "& + button": {
                                marginLeft: "20px",
                            },
                        },
                    },
                },
            }),
        },
        actionCardViewing: {
            // override options from `actionCard` subtheme
        },
        actionCardOffer: {
            // override options from `actionCardOffer` subtheme
            props: {
                actionWithActionButtons: true,
                actionItemDefaults: {
                    owner: {
                        default: [
                            {
                                type: "cta",
                                name: "accept",
                                crm_value: "accepted",
                                label: "Accept",
                                btnParams: {
                                    status: "accepted",
                                    role: "owner",
                                    form_name: "offer_accepted",
                                    successMessage: "Bid Accepted.",
                                },
                            },
                            {
                                type: "cta",
                                name: "reject",
                                crm_value: "rejected",
                                label: "Reject",
                                btnParams: {
                                    status: "rejected",
                                    role: "owner",
                                    form_name: "offer_rejected",
                                    successMessage: "Bid Rejected.",
                                },
                            },
                        ],
                    },
                    applicant: {
                        default: [
                            {
                                type: "cta",
                                name: "withdrawn",
                                crm_value: "withdrawn",
                                label: "Withdraw Bid",
                                btnParams: {
                                    status: "withdrawn",
                                    role: "applicant",
                                    form_name: "offer_withdrawn",
                                    successMessage: "Bid Withdrawn.",
                                },
                            },
                            {
                                type: "cta",
                                name: "revise_offer",
                                label: "Revise Bid",
                                nextStep: "actionReviseOffer",
                                nextStepParams: {
                                    role: "applicant",
                                    form_name: "revise_offer",
                                },
                            },
                        ],
                        actionReviseOffer: {
                            formParams: {
                                status: "pending",
                                role: "applicant",
                                form_name: "revise_offer",
                                successMessage:
                                    "Revised Bid Posted Successfully. Submitted bids are Subject to Contract / Contract Denied",
                            },
                            submit: {
                                submitLabel: "Revise Bid",
                            },
                        },
                    },
                },
            },
        },
        actionCardTenancy: {
            // override options from `actionCardTenancy` subtheme
        },
        actionCardFeedback: {
            // override options from `actionCardFeedback` subtheme
        },
        actionCardDocument: {
            // override options from `actionCardDocument` subtheme
        },
        actionCardWorksorder: {
            // override options from `actionCardWorksorder` subtheme
        },
        actionCardInspection: {
            // override options from `actionCardInspection` subtheme
        },
        actionCardAppointment: {
            // override options from `actionCardAppointment` subtheme
        },
        dialogDefault: {
            // options: src/components/MyAccount/modals/default/defaults.js
            props: {
                fullScreenProps: "md",
                slideDialogProps: {
                    direction: "up",
                },
                dialogProps: {},
                dialogTitleProps: {
                    align: "center",
                },
                dialogCloseProps: {
                    disableRipple: true,
                    edge: "end",
                    size: "small",
                },
                dialogContentProps: {},
            },
            // "sx": (theme) => ({
            //     // "display": "flex",
            //     // "alignItems": "flex-end",
            //     "& .dialogDefault-container": {
            //         // [theme.breakpoints.down('md')]: {
            //             // "position": "relative",
            //             // // "bottom": 0,
            //             // // "display": "flex",
            //             // // "alignItems": "flex-end",
            //             // "width": "100%",
            //             // "height": "100%",
            //         // },
            //     },
            //     "& .dialogDefault-paper": {
            //         [theme.breakpoints.up('md')]: {
            //             "borderRadius": "6px",
            //         },
            //         "& .MuiDialogTitle-root": { // This is the dialog title container
            //                 "color": "#000000",
            //             "& .MuiTypography-root": { // This is the title
            //                 // "maxWidth": "82%",
            //                 "marginRight": "20px",
            //                 "textAlign": "center",
            //             },
            //         },
            //     },
            //     "& .dialogDefault-paperFullScreen": {
            //         // "position": "absolute",
            //         // "bottom": 0,
            //         // "left": 0,
            //         // "height": "auto",
            //         "borderRadius": "6px 6px 0 0",
            //     },
            //     "& .dialogDefault-close": {
            //         "position": "absolute",
            //         "top": "20px",
            //         "right": "20px",
            //         "width": "24px",
            //         "height": "24px",
            //         "zIndex": 9999,
            //     },
            //     "& .dialogDefault-content, & .MuiDialogTitle-root + .dialogDefault-content": {
            //         "padding": theme.spacing(3.75, 5),
            //         [theme.breakpoints.up('md')]: {
            //             "padding": theme.spacing(3.75, 10),
            //         }
            //     }
            // })
        },
        dialogPreference: {
            // options: src/components/MyAccount/user/propertyPreference/dialogDefaults.js
            props: {
                dialogTitle:
                    "Please provide us with some further information to finish setting up your myDNG Account",
            },
        },
        dialogLogout: {
            // options: src/components/MyAccount/user/logoutDialog/defaults.js
        },
        dialogChildren: {
            // options: src/components/MyAccount/property/components/reportFaultBtn/dialogChildrenDefaults.js
        },
        actionCardDialog: {
            // options: src/components/MyAccount/modals/action/defaults.js
            subthemes: {
                contactModule: {
                    // Overriding how the contact module renders in action dialog
                    props: {
                        cardModule: {
                            elevation: 0,
                        },
                        cardModuleButtonTelProps: {
                            variant: "contained",
                            color: "secondary",
                        },
                        cardModuleButtonEmailProps: {
                            variant: "contained",
                            color: "secondary",
                        },
                        showWhatsapp: false,
                    },
                    // "sx": (theme) => ({
                    //     "& .contactModule": {
                    //         "padding": 0,
                    //         "borderRadius": 0,
                    //     },
                    //     "& .cardContent": {
                    //         "display": "flex",
                    //         "flexDirection": "column",
                    //         "alignItems": "center",
                    //         "padding": 0,
                    //         "textAlign": "center",
                    //     },
                    //     "& .cardModuleTitle": {
                    //         "marginBottom": theme.spacing(4),
                    //     },
                    //     "& .cardActions": {
                    //         "flexDirection": "column",
                    //         "padding": 0,
                    //         "marginTop": theme.spacing(3),
                    //         "& > div": {
                    //             "width": "100%",
                    //         }
                    //     },
                    //     "& .cardModuleUserTitle": {
                    //         "color": theme.palette.text.primary,
                    //     },
                    //     "& .cardModuleUserJobTitle": {
                    //         "color": theme.palette.text.primary,
                    //     },
                    //     "& .cardModuleButton": {
                    //         "width": "100%",
                    //         "marginTop": theme.spacing(2),
                    //     },
                    //     "& .avatar": {
                    //         "marginBottom": theme.spacing(2),
                    //         // "backgroundColor": "#ff0000",
                    //         "width": 80,
                    //         "height": 80,
                    //     },
                    //     "& .closeCard": {
                    //         "color": theme.palette.grey.grey2,
                    //         "marginTop": theme.spacing(3),
                    //     },
                    // }),
                },
            },
        },
        actionDialogChildren: {
            // options: src/components/MyAccount/actions/action/defaults.js
            props: {
                offerConfirmation: true,
            },
            subthemes: {
                globalForm: {
                    // Overriding how the globalForm renders in action dialog
                    props: {
                        formButton: {
                            variant: "contained",
                            color: "primary",
                            fullWidth: true,
                        },
                    },
                    // "sx": (theme) => ({
                    //     "& .globalForm-globalFormForm": {
                    //         "width": "100%",
                    //     },
                    //     "& .globalForm-fieldContainer": {
                    //         "display": "flex",
                    //         "flexDirection": "column",
                    //         "marginTop": theme.spacing(3),
                    //     },
                    //     "& .globalForm-textField": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(2.375),
                    //     },
                    //     "& .globalForm-selectField": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(3),
                    //     },
                    //     "& .react-datepicker": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(3),
                    //         "& .react-datepicker__time-container": {
                    //             "& li.react-datepicker__time-list-item": {
                    //                 "display": "flex",
                    //                 "alignItems": "center",
                    //                 "&.react-datepicker__time-list-item--disabled": {
                    //                     "display": "none",
                    //                 },
                    //             },
                    //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                    //                 "backgroundColor": theme.palette.primary.main,
                    //             },
                    //             "& .react-datepicker__day--selected": {
                    //                 "backgroundColor": theme.palette.primary.main,
                    //                 "color": "#fff",
                    //                 "&:hover": {
                    //                     "backgroundColor": theme.palette.primary.main,
                    //                 },
                    //             },
                    //         },
                    //     },
                    //     "& .suggestionsContainer": {
                    //         "position": "relative",
                    //         "& .suggestionsContainerOpen": {
                    //             "position": "absolute",
                    //             "zIndex": 998,
                    //             "top": "50px",
                    //             "left": 0,
                    //             "right": 0,
                    //         },
                    //         "& .suggestionsList": {
                    //             "margin": 0,
                    //             "padding": 0,
                    //             "listStyleType": "none",
                    //         },
                    //         "& .suggestion": {
                    //             "display": "block",
                    //             "padding": "10px 15px",
                    //             "&:hover": {
                    //                 "cursor": "pointer",
                    //                 "background": theme.palette.grey.grey3,
                    //             },
                    //         },
                    //     },
                    //     "& .globalForm-fieldsRadio": {
                    //         "marginBottom": theme.spacing(2),
                    //     },
                    //     "& .globalForm-actionContainer": {
                    //     },
                    //     "& .globalForm-fieldsTitle": {
                    //         "marginBottom": theme.spacing(3),
                    //     },
                    //     "& .globalForm-formButton": {

                    //         "marginTop": theme.spacing(2.75),
                    //     },
                    // }),
                },
            },
        },
        activityCard: {
            // options: src/components/MyAccount/activities/defaults.js
        },
        profileCard: {
            // options: src/components/MyAccount/user/profileCard/defaults.js
            props: {
                cardProps: {
                    // "variant": "outlined",
                    elevation: 0,
                },
            },
            sx: theme => ({
                marginBottom: theme.spacing(3.75),
                [theme.breakpoints.up("sm")]: {
                    marginBottom: theme.spacing(4),
                },
                [theme.breakpoints.up("lg")]: {
                    marginBottom: theme.spacing(6),
                },
                "& .profileCard-header": {
                    padding: 0,
                    "& .profileCard-avatar": {
                        marginRight: 0,
                        [theme.breakpoints.up("sm")]: {
                            marginRight: 0,
                        },
                    },
                    "& .profileCard-avatar-img": {
                        marginRight: theme.spacing(2.5),
                        [theme.breakpoints.up("sm")]: {
                            marginRight: theme.spacing(3.75),
                        },
                    },
                    "& .profileCard-title": {
                        fontWeight: 400,
                        fontSize: theme.h3,
                        lineHeight: theme.h3LineHm,
                        textTransform: "capitalize",
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.h3,
                            lineHeight: theme.h3LineHm,
                        },
                        [theme.breakpoints.down("sm")]: {
                            fontSize: theme.h5,
                            lineHeight: theme.h5LineHm,
                            marginTop: "20px",
                        },
                    },
                    "& .profileCard-subheader": {
                        color: theme.palette.text.primary,
                    },
                },
                "& .cardHeaderCaption": {
                    color: theme.palette.grey.grey2,
                    [theme.breakpoints.up("lg")]: {
                        display: "none",
                    },
                },
            }),
        },
        userAvatar: {
            // options: src/components/MyAccount/user/avatar/defaults.js
        },
        tabsMyAccount: {
            // options: src/components/MyAccount/tabs/defaults.js
            sx: theme => ({
                flexGrow: 1,
                marginBottom: theme.moduleSpacing,
                [theme.breakpoints.up("sm")]: {
                    marginBottom: theme.moduleSpacingSm,
                },
                [theme.breakpoints.up("lg")]: {
                    marginBottom: theme.moduleSpacingLg,
                },
                // "backgroundColor": theme.palette.background.paper,
                "& .tabsMyAccount-tabs": {
                    borderBottom: "1px solid #d8d8d8",
                    marginLeft: `-${theme.gridItemPmobile}`,
                    marginRight: `-${theme.gridItemPmobile}`,
                    [theme.breakpoints.up("sm")]: {
                        marginLeft: `-${theme.gridItemPtablet}`,
                        marginRight: `-${theme.gridItemPtablet}`,
                        paddingLeft: theme.gridItemPtablet,
                        paddingRight: theme.gridItemPtablet,
                    },
                    [theme.breakpoints.up("lg")]: {
                        marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                        marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                        paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                        paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                    },
                    "& .tabsMyAccount-indicator": {
                        height: "3px",
                        backgroundColor: theme.palette.secondary.main,
                    },
                    "& .tabsMyAccount-scrollButtons": {
                        alignItems: "flex-start",
                        "& svg": {
                            marginTop: "12px",
                        },
                        "&.Mui-disabled": {
                            opacity: 0.3,
                        },
                    },
                },
                "& .tabsMyAccount-tab": {
                    color: theme.palette.grey.grey2,
                    textTransform: "none",
                    [theme.breakpoints.up("sm")]: {
                        minWidth: "auto",
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginRight: theme.spacing(5),
                        fontWeight: 400,
                    },
                    [theme.breakpoints.up("md")]: {
                        paddingBottom: theme.spacing(2.5),
                    },
                    "&.tabsMyAccount-selected": {
                        color: theme.palette.secondary.main,
                        fontWeight: 500,
                    },
                    "&.tabsMyAccount-wrapper": {
                        // "color": theme.palette.grey.grey1,
                        fontSize: theme.bodySmall,
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.bodySmallLarge,
                            lineHeight: theme.lineHeightBSLargeSize,
                        },
                    },
                },
                "& .tabsMyAccount-tabPanel": {
                    padding: 0,
                },
                "& .tabsMyAccount-tabPanelBox": {
                    padding: theme.spacing(2.75, 0, 0, 0),
                },
            }),
        },
        calendar: {
            // options: src/components/MyAccount/calendar/defaults.js
        },
        simpleModule: {
            // options: src/components/MyAccount/simpleModule/defaults.js
            sx: theme => ({
                "& .simpleModule-cardModule": {
                    borderRadius: theme.moduleBorderRadius,
                },
                "& .simpleModule-cardModule-media": {
                    height: 140,
                },
                "& .simpleModule-cardContent": {
                    padding: theme.modulePadding,
                },
                "& .simpleModule-cardActions": {
                    padding: theme.modulePadding,
                    paddingTop: "0!important",
                },
                "& .simpleModule-cardModuleTitle": {
                    fontFamily: theme.tertiaryFontFamily,
                    fontSize: "2rem",
                    marginBottom: theme.spacing(1),
                },
                "& .simpleModule-cardModuleContent": {
                    color: "#6A6A6A",
                },
                "& .simpleModule-cardModuleButton": {
                    width: "100%",
                },
            }),
        },
        contactModule: {
            // options: src/components/MyAccount/contactModule/defaults.js
            props: {
                cardModuleButtonTelProps: {
                    variant: "contained",
                    color: "secondary",
                },
                cardModuleButtonEmailProps: {
                    variant: "contained",
                    color: "secondary",
                },
                showWhatsapp: false,
            },
        },
        simpleContactModule: {
            // options: src/components/MyAccount/contactModule/simpleContact.js
        },
        menuListIcons: {
            // options: src/components/MyAccount/iconMenuList/menuListIcons.js
        },
        menuListIconsTabs: {
            // options: src/components/MyAccount/iconMenuList/menuListIconsTabs.js
        },
        propertyResultComponent: {
            props: {
                noPropertyText:
                    "Properties that you Request to View or Request to bid on will appear here.",
            },
            subthemes: {
                results: {
                    // default property result styling
                    sx: theme => ({
                        [theme.breakpoints.up("sm")]: {
                            width: "auto",
                            margin: theme.spacing(0, -0.75),
                        },
                        [theme.breakpoints.up("lg")]: {
                            margin: theme.spacing(0, -2.375),
                        },
                    }),
                    subthemes: {
                        propertyresult: {
                            props: {
                                container: {
                                    order: ["media", "header", "aside"],
                                    sx: theme => ({
                                        flexDirection: "row",
                                        width: "100%",
                                        paddingTop: theme.spacing(1.125),
                                        paddingBottom: theme.spacing(1.125),
                                        [theme.breakpoints.up("sm")]: {
                                            flexDirection: "column",
                                            width: `calc(50% - ${theme.spacing(
                                                1.5
                                            )})`,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            marginBottom: theme.spacing(5),
                                            marginLeft: theme.spacing(0.75),
                                            marginRight: theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up("lg")]: {
                                            width: `calc(33.33% - ${theme.spacing(
                                                4.75
                                            )})`,
                                            marginLeft: theme.spacing(2.375),
                                            marginRight: theme.spacing(2.375),
                                        },
                                    }),
                                },
                                header: {
                                    sx: theme => ({
                                        width: "calc(100% - 110px)",
                                        [theme.breakpoints.up("sm")]: {
                                            width: "100%",
                                            marginTop: theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            fontWeight: 500,
                                            [theme.breakpoints.up("sm")]: {
                                                fontSize: theme.bodySmallLarge,
                                                lineHeight:
                                                    theme.lineHeightBLarge,
                                                marginBottom:
                                                    theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                color: theme.palette.grey.grey1,
                                            },
                                        },
                                    }),
                                },
                                aside: {
                                    sx: theme => ({
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        "& .propertyresult-price": {
                                            marginRight: "auto",
                                            [theme.breakpoints.up("sm")]: {
                                                fontSize: theme.bodyMedium,
                                                lineHeight:
                                                    theme.lineHeightBMedium,
                                            },
                                        },
                                    }),
                                },
                                price: {
                                    in: "aside",
                                    variant: "bodySmall",
                                    component: "p",
                                },
                                address: {
                                    in: "header",
                                    variant: "bodyMedium",
                                    component: "header",
                                },
                                offer: {
                                    in: "header",
                                    variant: "bodyMedium",
                                },
                                rooms: {
                                    in: "header",
                                },
                                // "save": {
                                //     "in": "aside",
                                // },
                                // "removeProperty": {
                                //     "in": "aside",
                                // },
                                media: {
                                    sx: theme => ({
                                        overflow: "hidden",
                                        marginRight: theme.spacing(2.375),
                                        "& .propertyresult-link-image": {
                                            display: "block",
                                        },
                                        "& .propertyresult-image": {
                                            display: "block",
                                            width: "90px",
                                            [theme.breakpoints.up("sm")]: {
                                                width: "100%",
                                            },
                                        },
                                        [theme.breakpoints.up("sm")]: {
                                            marginRight: 0,
                                        },
                                    }),
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            addressFormat: {
                                separator: " , ",
                                values: p =>
                                    [
                                        p.display_address,
                                        p.street_name,
                                        p.district,
                                        p.area,
                                    ]
                                        .filter(x => x)
                                        .shift(),
                                // "values": (p) => console.log(p)
                            },
                            roomsFormat: {
                                separator: " ",
                                values: p => [
                                    p.bedrooms,
                                    p.receptions,
                                    p.bathrooms,
                                ],
                            },
                        },
                    },
                },
            },
        },
        savedPropertyResultComponent: {
            subthemes: {
                results: {
                    // default property result styling
                    sx: theme => ({
                        [theme.breakpoints.up("sm")]: {
                            width: "auto",
                            margin: theme.spacing(0, -0.75),
                        },
                        [theme.breakpoints.up("lg")]: {
                            margin: theme.spacing(0, -2.375),
                        },
                    }),
                    subthemes: {
                        propertyresult: {
                            props: {
                                container: {
                                    order: ["media", "header", "aside"],
                                    sx: theme => ({
                                        flexDirection: "column",
                                        width: "100%",
                                        marginBottom: theme.spacing(3),
                                        [theme.breakpoints.up("sm")]: {
                                            width: `calc(50% - ${theme.spacing(
                                                1.5
                                            )})`,
                                            marginBottom: theme.spacing(5),
                                            marginLeft: theme.spacing(0.75),
                                            marginRight: theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up("lg")]: {
                                            width: `calc(33.33% - ${theme.spacing(
                                                4.75
                                            )})`,
                                            marginLeft: theme.spacing(2.375),
                                            marginRight: theme.spacing(2.375),
                                        },
                                    }),
                                },
                                header: {
                                    sx: theme => ({
                                        width: "100%",
                                        marginTop: theme.spacing(1.5),
                                        [theme.breakpoints.up("sm")]: {
                                            marginTop: theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            fontWeight: 500,
                                            [theme.breakpoints.up("sm")]: {
                                                fontSize: theme.bodySmallLarge,
                                                lineHeight:
                                                    theme.lineHeightBLarge,
                                                marginBottom:
                                                    theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                color: theme.palette.grey.grey1,
                                            },
                                        },
                                    }),
                                },
                                aside: {
                                    sx: theme => ({
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginTop: "auto",
                                        flexWrap: "wrap",
                                        "& .propertyresult-price": {
                                            marginTop: theme.spacing(0.75),
                                            order: 1,
                                            [theme.breakpoints.up("sm")]: {
                                                marginTop: theme.spacing(0),
                                                fontSize: theme.bodyMedium,
                                                lineHeight:
                                                    theme.lineHeightBMedium,
                                            },
                                        },
                                        "& .propertyresult-rooms": {
                                            order: 0,
                                            flexBasis: "100%",
                                            marginTop: theme.spacing(0.75),
                                            "& .roomsItem": {
                                                "& svg": {
                                                    color: theme.palette
                                                        .secondary.main,
                                                },
                                            },
                                        },
                                        "& .propertyresult-removeProperty": {
                                            order: 2,
                                        },
                                    }),
                                },
                                price: {
                                    in: "aside",
                                    variant: "bodySmall",
                                    component: "p",
                                },
                                address: {
                                    in: "header",
                                    variant: "bodyMedium",
                                    component: "header",
                                },
                                offer: {
                                    in: "header",
                                    variant: "bodyMedium",
                                },
                                flagBid: {
                                    in: "media",
                                    sx: theme => ({
                                        display: "none",
                                    }),
                                },
                                rooms: {
                                    in: "aside",
                                    component: "div",
                                },
                                summary: {
                                    in: "media",
                                    variant: "bodyMedium",
                                },
                                // "save": {
                                //     "in": "aside",
                                // },
                                removeProperty: {
                                    in: "aside",
                                },
                                media: {
                                    sx: {
                                        position: "relative",
                                        overflow: "hidden",
                                        "&.propertyresult-media": {
                                            width: "100%",
                                        },
                                        "& .propertyresult-link-image": {
                                            display: "block",
                                            overflow: "hidden",
                                            "&:after": {
                                                content: "''",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                background: "rgba(0,0,0,0.2)",
                                                zIndex: "2",
                                                pointerEvents: "none",
                                            },
                                        },
                                        "& .propertyresult-image": {
                                            display: "block",
                                            width: "100%",
                                        },
                                        "& .propertyresult-removeProperty": {
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "20",
                                            "& svg": {
                                                color: "#fff",
                                            },
                                        },
                                        "& .propertyresult-360tour": {
                                            position: "absolute",
                                            bottom: "15px",
                                            right: "15px",
                                            width: "auto",
                                            zIndex: 20,
                                        },
                                        "& .propertyresult-summary": {
                                            backgroundColor: "#1B1434",
                                            textAlign: "center",
                                            color: "#FFFFFF",
                                            fontWeight: 500,
                                            padding: "8px 20px",
                                            position: "inherit",
                                            zIndex: "3",
                                        },
                                    },
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            addressFormat: {
                                separator: " , ",
                                values: p =>
                                    [
                                        p.display_address,
                                        p.street_name,
                                        p.district,
                                        p.area,
                                    ]
                                        .filter(x => x)
                                        .shift(),
                                // "values": (p) => console.log(p)
                            },
                            roomsFormat: {
                                separator: " ",
                                values: p => [
                                    p.bedrooms,
                                    p.receptions,
                                    p.bathrooms,
                                ],
                            },
                            linkFormat: {
                                separator: "-",
                                target: "_blank",
                                values: p => propertyLink(p),
                                // "values": (p) => console.log(p)
                            },
                            // "lettingsPricePrefix": "",
                            lettingsPriceSuffix: " PCM",
                            // "salesPricePrefix": "",
                            // "salesPriceSuffix": "",
                        },
                    },
                },
            },
        },
        savedSearchResults: {
            props: {
                resultLinkFun: p => resultLinkFun(p),
                noactionTitle: "",
                noactionMessage:
                    "Looks like you don’t have any searches saved yet",
            },
            sx: theme => ({
                "& .content": {
                    display: "flex",
                    flexFlow: "column",
                },
                "& .box-container": {
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    border: "1px solid #DDDDDD",
                    borderRadius: "4px",
                    padding: theme.spacing(5),
                    order: "1",
                    "& .box-image": {
                        background: theme.palette.grey.grey3,
                        "& svg": {
                            fontSize: "2rem",
                            color: theme.palette.primary.icon,
                        },
                    },
                },
                "& .box-image-container": {
                    display: "none",
                    [theme.breakpoints.up("lg")]: {
                        display: "block",
                    },
                },
                "& .box-image-container, .box-btn-container, .box-content": {
                    [theme.breakpoints.down("lg")]: {
                        flexBasis: "100%",
                        maxWidth: "100%",
                    },
                },
                "& .box-content": {
                    [theme.breakpoints.down("lg")]: {
                        padding: "20px 0",
                        flexBasis: "100%",
                        maxWidth: "100%",
                    },
                    [theme.breakpoints.up("lg")]: {
                        paddingLeft: "10px",
                    },
                },
                "& .box-btn-container": {
                    textAlign: "left",
                    paddingTop: theme.spacing(1),
                    [theme.breakpoints.up("lg")]: {
                        textAlign: "right",
                        paddingTop: theme.spacing(0),
                    },
                },
                "& .box-container-title": {
                    fontFamily: theme.primaryFontFamily,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.grey.grey6,
                    marginBottom: "5px",
                    [theme.breakpoints.up("xl")]: {
                        marginBottom: "10px",
                    },
                },
                "& .box-container-text": {
                    fontFamily: theme.secondaryFontFamily,
                    fontSize: theme.bodyMedium,
                    lineHeight: theme.lineHeightBMedium,
                    fontWeight: "normal",
                    [theme.breakpoints.down("md")]: {
                        marginBottom: "5px",
                        display: "block",
                    },
                    color: theme.palette.grey.grey2,
                },
                "& .savedSearchResults-card": {
                    marginBottom: 0,
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    "&:last-child": {
                        marginBottom: theme.spacing(4),
                    },
                    "&.disabled": {
                        "& .savedSearchResults-cardHeader": {
                            "& > div": {
                                opacity: 0.5,
                            },
                            "& .savedSearchResults-action": {
                                opacity: 1,
                            },
                        },
                    },
                },
                "& .savedSearchResults-cardActionButton": {
                    display: "none",
                },
                "& .savedSearchResults-cardHeader": {
                    padding: theme.spacing(1, 0),
                    [theme.breakpoints.up("sm")]: {
                        padding: theme.spacing(1.5, 0),
                    },
                    "& .savedSearchResults-avatar": {
                        marginRight: 0,
                    },
                    "& .savedSearchResults-title": {
                        fontSize: theme.bodySmall,
                        lineHeight: theme.lineHeightBMedium,
                        marginBottom: theme.spacing(0.25),
                        fontFamily: theme.secondaryFontFamily,
                        color: theme.palette.grey.grey1,
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.bodyMedium,
                            lineHeight: theme.lineHeightBLarge,
                            marginBottom: theme.spacing(0.5),
                        },
                        [theme.breakpoints.up("md")]: {
                            fontSize: theme.bodySmallLarge,
                            lineHeight: theme.lineHeightBSLarge,
                        },
                    },
                    "& .savedSearchResults-subheader": {
                        color: "#6A6A6A",
                        [theme.breakpoints.up("sm")]: {
                            fontSize: theme.bodyMedium,
                            lineHeight: theme.lineHeightBMedium,
                        },
                    },
                    "& .savedSearchResults-action": {
                        alignSelf: "center",
                        marginRight: theme.spacing(0.5),
                        color: theme.palette.grey.grey2,
                    },
                },
                "& .savedSearchResults-cardContent": {
                    display: "none",
                    [theme.breakpoints.up("lg")]: {
                        display: "flex",
                    },
                    "& .cardContentItemName": {
                        fontFamily: theme.secondaryFontFamily,
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "22.5px",
                        color: theme.palette.grey.grey1,
                    },
                    "& .cardContentItemParams": {
                        fontFamily: theme.secondaryFontFamily,
                        color: theme.palette.grey.grey2,
                        textTransform: "capitalize",
                    },
                },
            }),
        },
        savedProperties: {
            props: {
                noActionMessage: "Get started in just a few seconds",
                containerText:
                    "Looking to buy or rent property in Ireland? Save your property preference and we will email you a list of properties that match your search criteria so that you do not miss out on the action.",
                resultLinkFun: p => resultLinkFun(p),
            },
            sx: theme => ({
                "& .box-container": {
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    border: "1px solid #DDDDDD",
                    borderRadius: "4px",
                    padding: theme.spacing(5),
                    "& .box-image": {
                        background: theme.palette.grey.grey3,
                        "& svg": {
                            fontSize: "2rem",
                            color: theme.palette.primary.icon,
                        },
                    },
                },
                "& .box-image-container": {
                    display: "none",
                    [theme.breakpoints.up("lg")]: {
                        display: "block",
                    },
                },
                "& .box-image-container, .box-btn-container, .box-content": {
                    [theme.breakpoints.down("lg")]: {
                        flexBasis: "100%",
                        maxWidth: "100%",
                    },
                },
                "& .box-content": {
                    [theme.breakpoints.down("lg")]: {
                        "padding-bottom": "10px",
                        flexBasis: "100%",
                        maxWidth: "100%",
                    },
                    [theme.breakpoints.up("lg")]: {
                        paddingLeft: "10px",
                    },
                },
                "& .box-btn-container": {
                    textAlign: "left",
                    paddingTop: theme.spacing(1),
                    [theme.breakpoints.up("lg")]: {
                        textAlign: "right",
                        paddingTop: theme.spacing(0),
                    },
                },
                "& .box-container-title": {
                    fontFamily: theme.primaryFontFamily,
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: theme.bodyMediumSize,
                    lineHeight: theme.h5LineH,
                    marginBottom: "5px",
                    [theme.breakpoints.up("xl")]: {
                        marginBottom: "10px",
                    },
                },
                "& .box-container-text": {
                    fontFamily: theme.secondaryFontFamily,
                    fontSize: theme.bodyMedium,
                    lineHeight: theme.lineHeightBMedium,
                    fontWeight: "normal",

                    [theme.breakpoints.down("md")]: {
                        marginBottom: "5px",
                        display: "block",
                    },
                    color: theme.palette.grey.grey2,
                },
            }),
        },
        latestProperties: {
            subthemes: {
                results: {
                    subthemes: {
                        propertyresult: {
                            props: {
                                container: {
                                    direction: "row",
                                    span: "full",
                                    order: ["media", "header"],
                                    sx: theme => ({
                                        paddingTop: theme.spacing(1.125),
                                        paddingBottom: theme.spacing(1.125),
                                        marginBottom: theme.moduleSpacing,
                                        [theme.breakpoints.up("sm")]: {
                                            paddingTop: theme.spacing(3.375),
                                            paddingBottom: theme.spacing(3.375),
                                            marginBottom: theme.moduleSpacingSm,
                                            borderBottom: `1px solid ${theme.palette.grey.grey4}`,
                                        },
                                        [theme.breakpoints.up("lg")]: {
                                            marginBottom: theme.moduleSpacingLg,
                                        },
                                        "&:last-child": {
                                            borderBottom: "none",
                                        },
                                    }),
                                },
                                header: {
                                    sx: {
                                        width: "calc(100% - 110px)",
                                        "& .propertyresult-address": {
                                            fontWeight: "bold",
                                        },
                                    },
                                },
                                price: {
                                    in: "header",
                                    variant: "bodySmall",
                                },
                                address: {
                                    in: "header",
                                    variant: "bodyMedium",
                                    component: "header",
                                    sx: {
                                        root: {
                                            fontWeight: "bold",
                                        },
                                    },
                                },
                                offer: {
                                    in: "header",
                                    variant: "bodyMedium",
                                },
                                media: {
                                    overflow: "hidden",
                                    width: 90,
                                    sx: theme => ({
                                        marginRight: theme.spacing(2.375),
                                        "& .propertyresult-link-image": {
                                            display: "block",
                                        },
                                        "& .propertyresult-image": {
                                            display: "block",
                                        },
                                    }),
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            address: {
                                separator: " • ",
                                // "values": (p) => [p.district,p.street_name].filter(x=>x).shift()
                                values: p =>
                                    [
                                        p.display_address,
                                        p.street_name,
                                        p.district,
                                        p.area,
                                    ]
                                        .filter(x => x)
                                        .shift()
                                        .toUpperCase() /*should really be CSS */,
                            },
                        },
                    },
                },
            },
        },
        singleProperty: {
            sx: theme => ({
                root: {
                    padding: theme.spacing(4, 5),
                    borderRadius: theme.moduleBorderRadius,
                    [theme.breakpoints.up("lg")]: {
                        marginTop: theme.spacing(0),
                    },
                },
            }),
            subthemes: {
                results: {
                    subthemes: {
                        propertyresult: {
                            props: {
                                container: {
                                    direction: "column",
                                    order: ["media", "header"],
                                    // "sx": (theme) => ({
                                    //     "paddingTop": theme.spacing(1.125),
                                    //     "paddingBottom": theme.spacing(1.125),
                                    //     [theme.breakpoints.up('sm')]: {
                                    //         "paddingTop": theme.spacing(3.375),
                                    //         "paddingBottom": theme.spacing(3.375),
                                    //         "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                    //     },
                                    //     "&:last-child": {
                                    //         "borderBottom": "none",
                                    //     },
                                    // }),
                                },
                                header: {
                                    sx: theme => ({
                                        width: "100%",
                                        "& .propertyresult-address": {
                                            marginTop: theme.spacing(3.25),
                                            marginBottom: theme.spacing(0.25),
                                            fontWeight: "bold",
                                        },
                                    }),
                                },
                                // "summary": {
                                //     "in": "media",
                                //     "variant": "bodyMedium",
                                // },
                                offer: {
                                    in: "header",
                                    variant: "bodyMedium",
                                },
                                address: {
                                    in: "header",
                                    variant: "bodyLarge",
                                    component: "header",
                                },
                                media: {
                                    sx: theme => ({
                                        overflow: "hidden",
                                        position: "relative",
                                        "& .propertyresult-link-image": {
                                            display: "block",
                                        },
                                        "& .propertyresult-image": {
                                            display: "block",
                                            width: "100%",
                                            height: "auto",
                                        },
                                        "& .propertyresult-summary": {
                                            textTransform: "capitalize",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            backgroundColor: "#4F4F4F",
                                            zIndex: 2,
                                            textAlign: "center",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            padding: theme.spacing(1),
                                        },
                                    }),
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            // "address": {
                            //     "separator": " • ",
                            //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                            // },
                        },
                    },
                },
            },
        },
        myPropertyPageSingleHeaderProperty: {
            sx: theme => ({
                width: `calc(100% + (${theme.gridItemPmobile} * 2))`,
                marginLeft: `-${theme.gridItemPmobile}`,
                marginRight: `-${theme.gridItemPmobile}`,
                marginBottom: theme.spacing(4),
                [theme.breakpoints.up("sm")]: {
                    width: `calc(100% + (${theme.gridItemPtablet} * 2))`,
                    marginLeft: `-${theme.gridItemPtablet}`,
                    marginRight: `-${theme.gridItemPtablet}`,
                    marginBottom: 0,
                },
            }),
            subthemes: {
                globalComponentLayout: {
                    sx: {
                        // Remove global space between components and modules
                        marginBottom: 0,
                    },
                },
                singleProperty: {
                    sx: theme => ({
                        root: {
                            padding: 0,
                            margin: 0,
                        },
                    }),
                    subthemes: {
                        results: {
                            subthemes: {
                                propertyresult: {
                                    props: {
                                        header: {
                                            sx: theme => ({
                                                width: "100%",
                                                "& .propertyresult-address": {
                                                    display: "none",
                                                    fontWeight: "normal",
                                                    margin: 0,
                                                    padding: `0 ${theme.gridItemPtablet}`,
                                                    [theme.breakpoints.up(
                                                        "sm"
                                                    )]: {
                                                        display: "flex",
                                                        padding: `0 ${theme.gridItemPtablet}`,
                                                        marginBottom:
                                                            theme.spacing(4),
                                                        marginTop:
                                                            theme.spacing(3.75),
                                                    },
                                                },
                                                "& .propertyresult-offerText": {
                                                    display: "none",
                                                    [theme.breakpoints.up(
                                                        "md"
                                                    )]: {
                                                        display: "flex",
                                                    },
                                                },
                                            }),
                                        },
                                        address: {
                                            in: "header",
                                            variant: "h3",
                                            component: "h2",
                                        },
                                        media: {
                                            sx: theme => ({
                                                position: "relative",
                                                overflow: "hidden",
                                                "& .propertyresult-link-image":
                                                    {
                                                        display: "block",
                                                    },
                                                "& .propertyresult-image": {
                                                    overflow: "hidden",
                                                    display: "block",
                                                    width: "100%",
                                                    height: "auto",
                                                    marginBottom: 0,
                                                },
                                                "& .propertyresult-summary": {
                                                    textTransform: "capitalize",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    backgroundColor: "#4F4F4F",
                                                    zIndex: 2,
                                                    textAlign: "center",
                                                    color: "#fff",
                                                    fontWeight: "bold",
                                                    padding: theme.spacing(1),
                                                },
                                            }),
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        profileMyAccountForm: {
            // options: src/components/MyAccount/user/profile/defaults.js
        },
        profileMyAccountChangePassword: {
            // options: src/components/MyAccount/user/password/defaults.js
        },
        profileMyAccountNotificationPreferences: {},
        profileDeleteAccount: {},
        pendingItems: {
            // options: src/components/MyAccount/progress/defaults.js
            sx: theme => ({
                "& .progressContainer": {
                    width: "100%",
                },
                "& .progressComponent-root": {
                    height: 5,
                    borderRadius: 2,
                },
                "& .progressComponent-colorPrimary": {
                    backgroundColor: "#dddddd",
                },
                "& .progressComponent-bar": {
                    borderRadius: 2,
                    backgroundColor: theme.palette.primary.main,
                },
                "& .progressValueTypography": {
                    fontFamily: theme.tertiaryFontFamily,
                    fontSize: "3.125rem",
                    color: theme.palette.primary.dark,
                    marginBottom: theme.spacing(1),
                    [theme.breakpoints.up("sm")]: {
                        fontSize: "3.5rem",
                        marginBottom: theme.spacing(1.8),
                    },
                },
                "& .pendingHeading": {
                    color: theme.palette.grey.grey2,
                    fontWeight: "bold",
                    marginBottom: theme.spacing(1.25),
                    [theme.breakpoints.up("sm")]: {
                        fontSize: theme.bodyMedium,
                        lineHeight: theme.lineHeightBLarge,
                        marginBottom: theme.spacing(2),
                    },
                },
                "& .pendingTextTitle": {
                    color: theme.palette.primary.dark,
                    fontWeight: "bold",
                    marginRight: 5,
                    [theme.breakpoints.up("sm")]: {
                        fontSize: theme.bodyLarge,
                        lineHeight: theme.lineHeightBLarge,
                    },
                },
                "& .pendingTextStatus": {
                    color: theme.palette.primary.dark,
                    [theme.breakpoints.up("sm")]: {
                        fontSize: theme.bodyLarge,
                        lineHeight: theme.lineHeightBLarge,
                    },
                },
                "& .pendingIconFinished": {
                    color: "#8ABB5A",
                    marginRight: theme.spacing(2),
                },
                "& .pendingIconPending": {
                    marginRight: theme.spacing(2),
                },
                "& .progressValueContainer": theme => ({
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: theme.spacing(3),
                    [theme.breakpoints.up("sm")]: {
                        marginBottom: theme.spacing(5),
                    },
                }),
                "& .progressValueContainerProgress": {
                    order: 0,
                    width: "100%",
                    mr: 1,
                },
                "& .progressValueContainerNumber": {
                    order: 1,
                },
                "& .pendingContainer": theme => ({
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: theme.spacing(1),
                    "&:last-child": {
                        marginBottom: 0,
                    },
                }),
                "& .pendingCircleStyle": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // "paddingBottom": theme.spacing(1),
                },
            }),
        },
        feedbackProgress: {
            // options: src/components/MyAccount/feedbackProgress/defaults.js
            subthemes: {
                globalComponentLayout: {
                    props: {
                        containerProps: {
                            elevation: 2,
                        },
                        contentProps: {},
                        titleProps: {
                            variant: "caption",
                            component: "h2",
                        },
                        titleLinkMoreProps: {
                            variant: "caption",
                        },
                        titleLinkMoreTooltipProps: {
                            title: "Tooltip",
                            placement: "top-end",
                            arrow: true,
                        },
                    },
                    sx: theme => ({
                        padding: 0,
                        borderRadius: theme.moduleBorderRadius,
                        [theme.breakpoints.down("sm")]: {
                            boxShadow: "none",
                        },
                        [theme.breakpoints.up("sm")]: {
                            padding: theme.modulePadding,
                        },
                        "& .globalComponentLayout-titleContainer": {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                            minHeight: 56,
                            paddingBottom: theme.spacing(2),
                            color: "red",
                            [theme.breakpoints.up("sm")]: {
                                paddingBottom: theme.spacing(3),
                            },
                        },
                        "& .globalComponentLayout-title": {
                            fontWeight: "bold",
                            color: theme.palette.grey.grey6,
                            fontSize: theme.h6,
                            [theme.breakpoints.up("sm")]: {
                                fontSize: theme.h5,
                                lineHeight: theme.h5LineH,
                                color: theme.palette.text.primary,
                            },
                        },
                        "& .globalComponentLayout-titleLinkMore": {
                            fontWeight: "bold",
                            color: theme.palette.grey.grey2,
                            marginLeft: "auto",
                            [theme.breakpoints.up("sm")]: {
                                fontSize: theme.bodyMedium,
                                lineHeight: theme.lineHeightBMedium,
                                fontWeight: "normal",
                                color: theme.palette.text.primary,
                            },
                        },
                    }),
                },
            },
        },
        swipeEdge: {
            // options: src/components/MyAccount/customSidebarDrawer/swipeEdgeDefaults.js
            subthemes: {
                menuListIconsTabs: {
                    sx: theme => ({
                        "& .menuListIconsTabs": {
                            padding: 0,
                            margin: 0,
                        },
                        "& .menuListIconsTabs-vertical": {},
                        "& .menuListIconsTabs-indicator": {
                            display: "none",
                        },
                        "& .menuListIconsTabs-tab": {
                            fontWeight: "bold",
                            textTransform: "none",
                            minHeight: "58px",
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            paddingTop: theme.spacing(1.5),
                            paddingBottom: theme.spacing(1.5),
                            paddingLeft: 0,
                            paddingRight: theme.gridItemPmobile,
                            opacity: 1,
                            [theme.breakpoints.up("sm")]: {
                                fontSize: theme.bodyMedium,
                                lineHeight: theme.lineHeightBMedium,
                                // "paddingLeft": theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up("md")]: {
                                padding: theme.spacing(1.5, 0),
                            },
                            "&.menuListIconsTabs-tab-wrapper": {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                            },
                        },
                        "& .menuListIconsTabs-tab-labelIcon svg": {
                            color: theme.palette.text.primary,
                            marginBottom: 0,
                            marginRight: theme.spacing(2.25),
                        },
                    }),
                },
            },
        },
        reportFaultBtn: {
            // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
            subthemes: {
                dialogChildren: {
                    subthemes: {
                        globalForm: {
                            // Overriding how the globalForm renders in action dialog
                            props: {
                                formButton: {
                                    variant: "contained",
                                    color: "primary",
                                    fullWidth: true,
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .globalForm-globalFormForm": {
                            //         "width": "100%",
                            //     },
                            //     "& .globalForm-fieldContainer": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //     },
                            //     "& .globalForm-textField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(2.375),
                            //     },
                            //     "& .globalForm-selectField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .react-datepicker": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //         "& .react-datepicker__time-container": {
                            //             "& li.react-datepicker__time-list-item": {
                            //                 "display": "flex",
                            //                 "alignItems": "center",
                            //                 "&.react-datepicker__time-list-item--disabled": {
                            //                     "display": "none",
                            //                 },
                            //             },
                            //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //             },
                            //             "& .react-datepicker__day--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //                 "color": "#fff",
                            //                 "&:hover": {
                            //                     "backgroundColor": theme.palette.primary.main,
                            //                 },
                            //             },
                            //         },
                            //     },
                            //     "& .suggestionsContainer": {
                            //         "position": "relative",
                            //         "& .suggestionsContainerOpen": {
                            //             "position": "absolute",
                            //             "zIndex": 998,
                            //             "top": "50px",
                            //             "left": 0,
                            //             "right": 0,
                            //         },
                            //         "& .suggestionsList": {
                            //             "margin": 0,
                            //             "padding": 0,
                            //             "listStyleType": "none",
                            //         },
                            //         "& .suggestion": {
                            //             "display": "block",
                            //             "padding": "10px 15px",
                            //             "&:hover": {
                            //                 "cursor": "pointer",
                            //                 "background": theme.palette.grey.grey3,
                            //             },
                            //         },
                            //     },
                            //     "& .globalForm-fieldsRadio": {
                            //         "marginBottom": theme.spacing(2),
                            //     },
                            //     "& .globalForm-actionContainer": {
                            //     },
                            //     "& .globalForm-fieldsTitle": {
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .globalForm-formButton": {
                            //         "width": "100%",
                            //     },
                            // }),
                        },
                    },
                },
            },
        },
        newDocumentBtn: {
            // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
            props: {},
            subthemes: {
                dialogChildren: {
                    subthemes: {
                        globalForm: {
                            // Overriding how the globalForm renders in action dialog
                            props: {
                                formButton: {
                                    variant: "contained",
                                    color: "primary",
                                    fullWidth: true,
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .globalForm-globalFormForm": {
                            //         "width": "100%",
                            //     },
                            //     "& .globalForm-fieldContainer": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //     },
                            //     "& .globalForm-textField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(2.375),
                            //     },
                            //     "& .globalForm-selectField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .react-datepicker": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //         "& .react-datepicker__time-container": {
                            //             "& li.react-datepicker__time-list-item": {
                            //                 "display": "flex",
                            //                 "alignItems": "center",
                            //                 "&.react-datepicker__time-list-item--disabled": {
                            //                     "display": "none",
                            //                 },
                            //             },
                            //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //             },
                            //             "& .react-datepicker__day--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //                 "color": "#fff",
                            //                 "&:hover": {
                            //                     "backgroundColor": theme.palette.primary.main,
                            //                 },
                            //             },
                            //         },
                            //     },
                            //     "& .suggestionsContainer": {
                            //         "position": "relative",
                            //         "& .suggestionsContainerOpen": {
                            //             "position": "absolute",
                            //             "zIndex": 998,
                            //             "top": "50px",
                            //             "left": 0,
                            //             "right": 0,
                            //         },
                            //         "& .suggestionsList": {
                            //             "margin": 0,
                            //             "padding": 0,
                            //             "listStyleType": "none",
                            //         },
                            //         "& .suggestion": {
                            //             "display": "block",
                            //             "padding": "10px 15px",
                            //             "&:hover": {
                            //                 "cursor": "pointer",
                            //                 "background": theme.palette.grey.grey3,
                            //             },
                            //         },
                            //     },
                            //     "& .globalForm-fieldsRadio": {
                            //         "marginBottom": theme.spacing(2),
                            //     },
                            //     "& .globalForm-actionContainer": {
                            //     },
                            //     "& .globalForm-fieldsTitle": {
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .globalForm-formButton": {
                            //         "width": "100%",
                            //     },
                            // }),
                        },
                    },
                },
            },
        },
        simpleValuationModule: {
            subthemes: {
                dialogChildren: {
                    subthemes: {
                        globalForm: {
                            // Overriding how the globalForm renders in action dialog
                            props: {
                                formButton: {
                                    variant: "contained",
                                    color: "primary",
                                    fullWidth: true,
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .globalForm-globalFormForm": {
                            //         "width": "100%",
                            //     },
                            //     "& .globalForm-fieldContainer": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //     },
                            //     "& .globalForm-textField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(2.375),
                            //     },
                            //     "& .globalForm-selectField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .react-datepicker": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //         "& .react-datepicker__time-container": {
                            //             "& li.react-datepicker__time-list-item": {
                            //                 "display": "flex",
                            //                 "alignItems": "center",
                            //                 "&.react-datepicker__time-list-item--disabled": {
                            //                     "display": "none",
                            //                 },
                            //             },
                            //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //             },
                            //             "& .react-datepicker__day--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //                 "color": "#fff",
                            //                 "&:hover": {
                            //                     "backgroundColor": theme.palette.primary.main,
                            //                 },
                            //             },
                            //         },
                            //     },
                            //     "& .suggestionsContainer": {
                            //         "position": "relative",
                            //         "& .suggestionsContainerOpen": {
                            //             "position": "absolute",
                            //             "zIndex": 998,
                            //             "top": "50px",
                            //             "left": 0,
                            //             "right": 0,
                            //         },
                            //         "& .suggestionsList": {
                            //             "margin": 0,
                            //             "padding": 0,
                            //             "listStyleType": "none",
                            //         },
                            //         "& .suggestion": {
                            //             "display": "block",
                            //             "padding": "10px 15px",
                            //             "&:hover": {
                            //                 "cursor": "pointer",
                            //                 "background": theme.palette.grey.grey3,
                            //             },
                            //         },
                            //     },
                            //     "& .globalForm-fieldsRadio": {
                            //         "marginBottom": theme.spacing(2),
                            //     },
                            //     "& .globalForm-actionContainer": {
                            //     },
                            //     "& .globalForm-fieldsTitle": {
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .globalForm-formButton": {
                            //         "width": "100%",
                            //     },
                            // }),
                        },
                    },
                },
            },
        },
        contactBtn: {
            // options: src/components/MyAccount/property/components/contactBtn/defaults.js
            subthemes: {
                dialogChildren: {
                    subthemes: {
                        globalForm: {
                            // Overriding how the globalForm renders in action dialog
                            props: {
                                formButton: {
                                    variant: "contained",
                                    color: "primary",
                                    fullWidth: true,
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .globalForm-globalFormForm": {
                            //         "width": "100%",
                            //     },
                            //     "& .globalForm-fieldContainer": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //     },
                            //     "& .globalForm-textField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(2.375),
                            //     },
                            //     "& .globalForm-selectField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .react-datepicker": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //         "& .react-datepicker__time-container": {
                            //             "& li.react-datepicker__time-list-item": {
                            //                 "display": "flex",
                            //                 "alignItems": "center",
                            //                 "&.react-datepicker__time-list-item--disabled": {
                            //                     "display": "none",
                            //                 },
                            //             },
                            //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //             },
                            //             "& .react-datepicker__day--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //                 "color": "#fff",
                            //                 "&:hover": {
                            //                     "backgroundColor": theme.palette.primary.main,
                            //                 },
                            //             },
                            //         },
                            //     },
                            //     "& .suggestionsContainer": {
                            //         "position": "relative",
                            //         "& .suggestionsContainerOpen": {
                            //             "position": "absolute",
                            //             "zIndex": 998,
                            //             "top": "50px",
                            //             "left": 0,
                            //             "right": 0,
                            //         },
                            //         "& .suggestionsList": {
                            //             "margin": 0,
                            //             "padding": 0,
                            //             "listStyleType": "none",
                            //         },
                            //         "& .suggestion": {
                            //             "display": "block",
                            //             "padding": "10px 15px",
                            //             "&:hover": {
                            //                 "cursor": "pointer",
                            //                 "background": theme.palette.grey.grey3,
                            //             },
                            //         },
                            //     },
                            //     "& .globalForm-fieldsRadio": {
                            //         "marginBottom": theme.spacing(2),
                            //     },
                            //     "& .globalForm-actionContainer": {
                            //     },
                            //     "& .globalForm-fieldsTitle": {
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .globalForm-formButton": {
                            //         "width": "100%",
                            //     },
                            // }),
                        },
                    },
                },
            },
        },
        makeOfferBtn: {
            // options: src/components/MyAccount/property/components/makeOffer/defaults.js
            props: {
                confirmation: true,
                btnLabelProps: "Click to bid",
                identityChecks: identityChecks,
                identityChecksDisclaimer: `
                <ul><li>A valid current Proof of Funds is required. </li>

                <li>If you are mortgage approved please upload your Approval In Principle in date. </li>

                <li>If you are a cash buyer please upload an up to date image/screenshot of your cash funds. Name on the account must be the same as the buyers (your) name.</li>

                <li>If the bid is a combination of gift letter and cash or AIP, please upload the gift letter and either AIP or Cash buyer proof as above.</li>


                <li>Our agent will review the documentation and may ask for clarification. <b><i>(Uploaded Doc/PDF/Image must not be greater that 4mb)</i></></li>
                </ul>
                `,
                modalTitle: "Enter bid amount",
                makeAnOfferStatusAllowed: ["For Sale", "forSaleUnavailable"],
                // makeAnOfferOffice: ["TER", "BRA", "FDY", "TAL"], // enable if any branch restriction needed
                hasSpecialAttrCheck: "Online Bidding",

                customValidation: {
                    field: "amount",
                    rule: data => {
                        let ruleVal = data % 100
                        if (ruleVal === 0) {
                            return true
                        }
                        return false
                    },
                    errorMessage: `Bid amount can be increment by 100s`,
                },
            },
            subthemes: {
                dialogChildren: {
                    props: {
                        successMsgForSale:
                            "Thank you for making a bid on this property. Submitted bids are Subject to Contract / Contract Denied",
                        successMsgForLet:
                            "Thank you for making a bid on this property. Submitted bids are Subject to Contract / Contract Denied",
                    },
                    subthemes: {
                        globalForm: {
                            // Overriding how the globalForm renders in action dialog
                            props: {
                                formButton: {
                                    variant: "contained",
                                    color: "primary",
                                    fullWidth: true,
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .globalForm-globalFormForm": {
                            //         "width": "100%",
                            //     },
                            //     "& .globalForm-fieldContainer": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //     },
                            //     "& .globalForm-textField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(2.375),
                            //     },
                            //     "& .globalForm-selectField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .react-datepicker": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //         "& .react-datepicker__time-container": {
                            //             "& li.react-datepicker__time-list-item": {
                            //                 "display": "flex",
                            //                 "alignItems": "center",
                            //                 "&.react-datepicker__time-list-item--disabled": {
                            //                     "display": "none",
                            //                 },
                            //             },
                            //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //             },
                            //             "& .react-datepicker__day--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //                 "color": "#fff",
                            //                 "&:hover": {
                            //                     "backgroundColor": theme.palette.primary.main,
                            //                 },
                            //             },
                            //         },
                            //     },
                            //     "& .suggestionsContainer": {
                            //         "position": "relative",
                            //         "& .suggestionsContainerOpen": {
                            //             "position": "absolute",
                            //             "zIndex": 998,
                            //             "top": "50px",
                            //             "left": 0,
                            //             "right": 0,
                            //         },
                            //         "& .suggestionsList": {
                            //             "margin": 0,
                            //             "padding": 0,
                            //             "listStyleType": "none",
                            //         },
                            //         "& .suggestion": {
                            //             "display": "block",
                            //             "padding": "10px 15px",
                            //             "&:hover": {
                            //                 "cursor": "pointer",
                            //                 "background": theme.palette.grey.grey3,
                            //             },
                            //         },
                            //     },
                            //     "& .globalForm-fieldsRadio": {
                            //         "marginBottom": theme.spacing(2),
                            //     },
                            //     "& .globalForm-actionContainer": {
                            //     },
                            //     "& .globalForm-fieldsTitle": {
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .globalForm-formButton": {
                            //         "width": "100%",
                            //     },
                            // }),
                        },
                    },
                },
            },
        },
        bookViewingBtn: {
            // options: src/components/MyAccount/property/components/bookViewing/defaults.js
            subthemes: {
                dialogChildren: {
                    props: {
                        successMessage:
                            "Your viewing request has been submitted to the agent/office for review. Please note, your viewing date and time is <b>not</b> confirmed until the agent contacts you directly.",
                    },
                    subthemes: {
                        globalForm: {
                            // Overriding how the globalForm renders in action dialog
                            props: {
                                formButton: {
                                    variant: "contained",
                                    color: "primary",
                                    fullWidth: true,
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .globalForm-globalFormForm": {
                            //         "width": "100%",
                            //     },
                            //     "& .globalForm-fieldContainer": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //     },
                            //     "& .globalForm-textField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(2.375),
                            //     },
                            //     "& .globalForm-selectField": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .react-datepicker": {
                            //         "width": "100%",
                            //         "marginBottom": theme.spacing(3),
                            //         "& .react-datepicker__time-container": {
                            //             "& li.react-datepicker__time-list-item": {
                            //                 "display": "flex",
                            //                 "alignItems": "center",
                            //                 "&.react-datepicker__time-list-item--disabled": {
                            //                     "display": "none",
                            //                 },
                            //             },
                            //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //             },
                            //             "& .react-datepicker__day--selected": {
                            //                 "backgroundColor": theme.palette.primary.main,
                            //                 "color": "#fff",
                            //                 "&:hover": {
                            //                     "backgroundColor": theme.palette.primary.main,
                            //                 },
                            //             },
                            //         },
                            //     },
                            //     "& .suggestionsContainer": {
                            //         "position": "relative",
                            //         "& .suggestionsContainerOpen": {
                            //             "position": "absolute",
                            //             "zIndex": 998,
                            //             "top": "50px",
                            //             "left": 0,
                            //             "right": 0,
                            //         },
                            //         "& .suggestionsList": {
                            //             "margin": 0,
                            //             "padding": 0,
                            //             "listStyleType": "none",
                            //         },
                            //         "& .suggestion": {
                            //             "display": "block",
                            //             "padding": "10px 15px",
                            //             "&:hover": {
                            //                 "cursor": "pointer",
                            //                 "background": theme.palette.grey.grey3,
                            //             },
                            //         },
                            //     },
                            //     "& .globalForm-fieldsRadio": {
                            //         "marginBottom": theme.spacing(2),
                            //     },
                            //     "& .globalForm-actionContainer": {
                            //     },
                            //     "& .globalForm-fieldsTitle": {
                            //         "marginBottom": theme.spacing(3),
                            //     },
                            //     "& .globalForm-formButton": {
                            //         "width": "100%",
                            //     },
                            // }),
                        },
                    },
                },
            },
        },
        removePropertyPersona: {
            // options: src/components/MyAccount/property/components/removePropertyPersona/defaults.js
            subthemes: {
                dialogDefault: {
                    props: {
                        dialogTitleProps: {
                            align: "left",
                        },
                    },
                    sx: theme => ({
                        "& .dialogDefault-container": {},
                        "& .dialogDefault-paper": {
                            [theme.breakpoints.up("md")]: {
                                borderRadius: "6px",
                            },
                            "& .MuiDialogTitle-root": {
                                // This is the dialog title container
                                "& .MuiTypography-root": {
                                    // This is the title
                                    marginRight: "20px",
                                    textAlign: "center",
                                },
                            },
                        },
                        "& .dialogDefault-paperFullScreen": {
                            borderRadius: "6px 6px 0 0",
                        },
                        "& .dialogDefault-close": {
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            width: "24px",
                            height: "24px",
                            zIndex: 9999,
                        },
                        "& .dialogDefault-content": {},
                    }),
                },
            },
        },
        homeReportBtn: {
            // options: src/components/MyAccount/property/components/homeReport/defaults.js
        },
        floatingContainer: {
            // options: src/components/MyAccount/floatingContainer/defaults.js
        },
        fabBtn: {
            // options: src/components/MyAccount/fabBtn/defaults.js
            props: {
                containerText:
                    "Looking to buy or rent property in Ireland? Save your property preference and we will email you a list of properties that match your search criteria so that you do not miss out on the action.",
                defaultArea: defaultArea,
                defaultSearchType: "",
            },
            subthemes: {
                globalForm: {
                    // Overriding how the globalForm renders in action dialog
                    props: {
                        formButton: {
                            variant: "contained",
                            color: "secondary",
                            fullWidth: true,
                        },
                    },
                    // "sx": (theme) => ({
                    //     "& .globalForm-globalFormForm": {
                    //         "width": "100%",
                    //     },

                    //     "& .globalForm-fieldContainer": {
                    //         "display": "flex",
                    //         "flexDirection": "column",
                    //         "marginTop": theme.spacing(2),
                    //     },
                    //     "& .globalForm-textField": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(2.375),
                    //     },
                    //     "& .globalForm-selectField": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(3),
                    //     },
                    //     "& .globalForm-jsonAutocompleteField": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(3),
                    //         "& .MuiOutlinedInput-root":{
                    //             "fontFamily": theme.secondaryFontFamily,
                    //             '&.Mui-focused fieldset': {
                    //                 "borderColor": theme.palette.primary.main,
                    //             },
                    //         },
                    //         "& .MuiInputLabel-root": {
                    //             "color": theme.palette.grey.grey1,
                    //             "fontFamily": theme.secondaryFontFamily,
                    //         },
                    //         '& label.Mui-focused': {
                    //             "color": theme.palette.primary.main,
                    //         },
                    //     },
                    //     "& .react-datepicker": {
                    //         "width": "100%",
                    //         "marginBottom": theme.spacing(3),
                    //         "& .react-datepicker__time-container": {
                    //             "& li.react-datepicker__time-list-item": {
                    //                 "display": "flex",
                    //                 "alignItems": "center",
                    //                 "&.react-datepicker__time-list-item--disabled": {
                    //                     "display": "none",
                    //                 },
                    //             },
                    //             "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                    //                 "backgroundColor": theme.palette.primary.main,
                    //             },
                    //             "& .react-datepicker__day--selected": {
                    //                 "backgroundColor": theme.palette.primary.main,
                    //                 "color": "#fff",
                    //                 "&:hover": {
                    //                     "backgroundColor": theme.palette.primary.main,
                    //                 },
                    //             },
                    //         },
                    //     },
                    //     "& .suggestionsContainer": {
                    //         "position": "relative",
                    //         "& .suggestionsContainerOpen": {
                    //             "position": "absolute",
                    //             "zIndex": 998,
                    //             "top": "50px",
                    //             "left": 0,
                    //             "right": 0,
                    //         },
                    //         "& .suggestionsList": {
                    //             "margin": 0,
                    //             "padding": 0,
                    //             "listStyleType": "none",
                    //         },
                    //         "& .suggestion": {
                    //             "display": "block",
                    //             "padding": "10px 15px",
                    //             "&:hover": {
                    //                 "cursor": "pointer",
                    //                 "background": theme.palette.grey.grey3,
                    //             },
                    //         },
                    //     },
                    //     "& .globalForm-fieldsRadio": {
                    //         "marginBottom": theme.spacing(2),
                    //     },
                    //     "& .globalForm-actionContainer": {
                    //     },
                    //     "& .globalForm-fieldsTitle": {
                    //         "marginBottom": theme.spacing(3),
                    //         "color": "#3D3D3D",
                    //         "fontFamily": theme.secondaryFontFamily,
                    //     },
                    //     "& .globalForm-formButton": {
                    //     },
                    // }),
                },
            },
        },
        currentTenancy: {
            // options: src/components/MyAccount/currentTenancy/defaults.js
        },
        payments: {
            // options: src/components/MyAccount/payments/defaults.js
        },
        newsBannerImage: {
            // options: src/components/MyAccount/blog/components/bannerImage/defaults.js
        },
        homePage: {
            subthemes: {
                tabsMyAccount: {
                    // override styling for tabs on homepage subtheme
                    sx: theme => ({
                        flexGrow: 1,
                        marginBottom: theme.moduleSpacing,
                        [theme.breakpoints.up("sm")]: {
                            marginBottom: theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up("lg")]: {
                            marginBottom: theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            borderBottom: "1px solid #d8d8d8",
                            marginLeft: `-${theme.gridItemPmobile}`,
                            marginRight: `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: `-${theme.gridItemPtablet}`,
                                marginRight: `-${theme.gridItemPtablet}`,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up("lg")]: {
                                borderBottom: "none",
                                marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                height: "3px",
                                backgroundColor: theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                alignItems: "flex-start",
                                "& svg": {
                                    marginTop: "12px",
                                },
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            textTransform: "none",
                            fontFamily: theme.secondaryFontFamily,
                            color: theme.palette.grey.grey1,
                            opacity: 0.4,
                            [theme.breakpoints.up("sm")]: {
                                minWidth: "auto",
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: theme.spacing(5),
                                fontWeight: 400,
                            },
                            [theme.breakpoints.up("md")]: {
                                paddingBottom: theme.spacing(2.5),
                            },
                            "&.tabsMyAccount-selected": {
                                fontWeight: 500,
                                color: theme.palette.secondary.main,
                                opacity: 1,
                            },
                            "& .tabsMyAccount-wrapper": {
                                fontSize: theme.bodySmall,
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: theme.typography.h5.fontSize,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            padding: 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            padding: theme.spacing(2.75, 0, 0, 0),
                        },
                    }),
                },
            },
        },
        homePage2: {
            props: {
                hideValuationModule: true,
                showContactModule: true,
            },
            staticModules: [
                {
                    imageBox: {
                        title: "Our Services",
                        moreLabel: null,
                        moreLinkUrl: null,
                        titleLinkMoreTooltipProps: null,
                        items: [
                            {
                                title: "Find a Property for Sale",
                                subtitle: "Let’s find your new home together",
                                linkUrl: `${WEBSITE_URL}/property/for-sale/in-ireland/`,
                                imgUrl: `/images/${themeName}/find-a-property-for-sale.jpg`,
                                imgAlt: "",
                            },
                            {
                                title: "Find a Property to Rent",
                                subtitle: "Let’s find your new home together",
                                linkUrl: `${WEBSITE_URL}/property/to-rent/in-ireland/`,
                                imgUrl: `/images/${themeName}/find-a-property-to-rent.jpg`,
                                imgAlt: "",
                            },
                            {
                                title: "Meet the Team",
                                subtitle: "Speak to one of our experts",
                                linkUrl: `${WEBSITE_URL}/contact-dng-estate-agents/find-a-dng-team-member/`,
                                imgUrl: `/images/${themeName}/meet-the-team.jpg`,
                                imgAlt: "",
                            },
                        ],
                    },
                },
            ],
            subthemes: {
                tabsMyAccount: {
                    // override styling for tabs on homepage subtheme
                    sx: theme => ({
                        flexGrow: 1,
                        marginBottom: theme.moduleSpacing,
                        [theme.breakpoints.up("sm")]: {
                            marginBottom: theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up("lg")]: {
                            marginBottom: theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            borderBottom: "1px solid #d8d8d8",
                            marginLeft: `-${theme.gridItemPmobile}`,
                            marginRight: `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: `-${theme.gridItemPtablet}`,
                                marginRight: `-${theme.gridItemPtablet}`,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up("lg")]: {
                                borderBottom: "none",
                                marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                height: "3px",
                                // "backgroundColor": theme.palette.primary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                alignItems: "flex-start",
                                "& svg": {
                                    marginTop: "12px",
                                },
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            color: theme.palette.grey.grey2,
                            textTransform: "none",
                            [theme.breakpoints.up("sm")]: {
                                minWidth: "auto",
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: theme.spacing(5),
                                fontWeight: 400,
                            },
                            [theme.breakpoints.up("md")]: {
                                paddingBottom: theme.spacing(2.5),
                            },
                            "&.tabsMyAccount-selected": {
                                color: theme.palette.secondary.main,
                                fontWeight: 500,
                            },
                            "&.tabsMyAccount-wrapper": {
                                fontSize: theme.bodySmall,
                                lineHeight: theme.lineHeightBSmall,
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: theme.bodySmallLarge,
                                    lineHeight: theme.lineHeightBSLarge,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            padding: 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            padding: theme.spacing(2.75, 0, 0, 0),
                        },
                    }),
                },
                propertyResultComponent: {
                    subthemes: {
                        results: {
                            // default property result styling
                            sx: theme => ({
                                [theme.breakpoints.up("sm")]: {
                                    width: "auto",
                                    margin: theme.spacing(0, -0.75),
                                },
                                [theme.breakpoints.up("lg")]: {
                                    margin: theme.spacing(0, -2.375),
                                },
                            }),
                            subthemes: {
                                propertyresult: {
                                    props: {
                                        container: {
                                            order: ["media", "header", "aside"],
                                            sx: theme => ({
                                                flexDirection: "row",
                                                width: "100%",
                                                paddingTop:
                                                    theme.spacing(1.125),
                                                paddingBottom:
                                                    theme.spacing(1.125),
                                                [theme.breakpoints.up("sm")]: {
                                                    flexDirection: "column",
                                                    width: `calc(50% - ${theme.spacing(
                                                        1.5
                                                    )})`,
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    marginBottom:
                                                        theme.spacing(5),
                                                    marginLeft:
                                                        theme.spacing(0.75),
                                                    marginRight:
                                                        theme.spacing(0.75),
                                                },
                                                [theme.breakpoints.up("lg")]: {
                                                    width: `calc(33.33% - ${theme.spacing(
                                                        4.75
                                                    )})`,
                                                    marginLeft:
                                                        theme.spacing(2.375),
                                                    marginRight:
                                                        theme.spacing(2.375),
                                                    marginBottom: 0,
                                                },
                                            }),
                                        },
                                        header: {
                                            sx: theme => ({
                                                width: "calc(100% - 110px)",
                                                [theme.breakpoints.up("sm")]: {
                                                    width: "100%",
                                                    marginTop:
                                                        theme.spacing(3.25),
                                                },
                                                "& .propertyresult-address": {
                                                    fontWeight: 500,
                                                    [theme.breakpoints.up(
                                                        "sm"
                                                    )]: {
                                                        fontSize:
                                                            theme.bodySmallLarge,
                                                        lineHeight:
                                                            theme.lineHeightBLarge,
                                                        marginBottom:
                                                            theme.spacing(0.25),
                                                    },
                                                    "& .propertyresult-link-image":
                                                        {
                                                            color: theme.palette
                                                                .grey.grey1,
                                                        },
                                                },
                                                "& .propertyresult-price": {
                                                    color: theme.palette.grey
                                                        .grey2,
                                                    [theme.breakpoints.up(
                                                        "sm"
                                                    )]: {
                                                        fontSize:
                                                            theme.bodyMedium,
                                                        lineHeight:
                                                            theme.lineHeightBMedium,
                                                    },
                                                },
                                            }),
                                        },
                                        price: {
                                            in: "header",
                                            variant: "bodySmall",
                                        },
                                        address: {
                                            in: "header",
                                            variant: "bodyMedium",
                                            component: "header",
                                        },
                                        offer: {
                                            in: "header",
                                            variant: "bodyMedium",
                                        },
                                        rooms: {
                                            in: null,
                                        },
                                        // "save": {
                                        //     "in": "aside",
                                        // },
                                        // "removeProperty": {
                                        //     "in": "aside",
                                        // },
                                        media: {
                                            sx: theme => ({
                                                overflow: "hidden",
                                                marginRight:
                                                    theme.spacing(2.375),
                                                "&.propertyresult-media": {
                                                    [theme.breakpoints.up(
                                                        "sm"
                                                    )]: {
                                                        width: "100%",
                                                    },
                                                },
                                                "& .propertyresult-link-image":
                                                    {
                                                        display: "block",
                                                    },
                                                "& .propertyresult-image": {
                                                    display: "block",
                                                    width: "90px",
                                                    [theme.breakpoints.up(
                                                        "sm"
                                                    )]: {
                                                        width: "100%",
                                                    },
                                                },
                                                [theme.breakpoints.up("sm")]: {
                                                    marginRight: 0,
                                                },
                                            }),
                                        },
                                    },

                                    // "typography": {
                                    //     "h6": { "textTransform": "none" },
                                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                                    //     "subtitle2": { "textTransform": "none" },
                                    // },

                                    // "address": {
                                    //     "separator": " • ",
                                    //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                                    // },
                                },
                            },
                        },
                    },
                },
                activityCard: {
                    subthemes: {
                        actionCard: {
                            sx: theme => ({
                                "&.actionCard-card": {
                                    marginBottom: 0,
                                    paddingTop: theme.spacing(1),
                                    paddingBottom: theme.spacing(1),
                                    "&.disabled": {
                                        opacity: 0.5,
                                    },
                                    "&.other-bidder": {
                                        opacity: 0.5,
                                    },
                                },
                                "& .actionCard-cardActionButton": {
                                    backgroundColor: theme.palette.grey.grey4,
                                    color: theme.palette.primary.dark,
                                },
                                "& .actionCard-cardHeader": {
                                    padding: theme.spacing(1, 0),
                                    [theme.breakpoints.up("sm")]: {
                                        padding: theme.spacing(1.5, 0),
                                    },
                                    "& .cardHeader-avatar": {
                                        marginLeft: "auto",
                                        marginRight: theme.spacing(1.875),
                                        [theme.breakpoints.up("sm")]: {
                                            marginRight: theme.spacing(3.5),
                                        },
                                    },
                                    "& .cardHeader-title": {
                                        fontFamily: theme.secondaryFontFamily,
                                        color: theme.palette.grey.grey1,
                                        fontSize: theme.bodySmall,
                                        marginBottom: theme.spacing(0.25),
                                        [theme.breakpoints.up("sm")]: {
                                            fontSize: theme.bodyLarge,
                                            lineHeight: theme.lineHeightBLarge,
                                            marginBottom: theme.spacing(0.5),
                                        },
                                        [theme.breakpoints.up("md")]: {
                                            fontSize: theme.bodySmallLarge,
                                            lineHeight: theme.lineHeightBSLarge,
                                            marginBottom: theme.spacing(0.5),
                                        },
                                    },
                                    "& .cardHeader-subheader": {
                                        color: theme.palette.text.primary,
                                        [theme.breakpoints.up("sm")]: {
                                            fontSize: theme.bodyMedium,
                                            lineHeight: theme.lineHeightBMedium,
                                        },
                                        [theme.breakpoints.up("lg")]: {
                                            fontSize: theme.bodyXSmall,
                                            lineHeight: theme.lineHeightBXSmall,
                                        },
                                    },
                                    "& .cardHeader-action": {
                                        alignSelf: "center",
                                        marginRight: theme.spacing(0.5),
                                    },
                                },
                            }),
                        },
                    },
                },
            },
        },
        myPropertyPage: {
            props: {
                tab: {
                    default: MYACCOUNT_LIGHT ? "wishlist" : "",
                    // This can be a array or objects
                    items: MYACCOUNT_LIGHT
                        ? ["wishlist", "saved-search"]
                        : [
                              "all-properties",
                              "wishlist",
                              "saved-search",
                              "archived",
                          ],
                },
            },
            staticModules: [
                {
                    imageBox: {
                        title: "Our Services",
                        moreLabel: null,
                        moreLinkUrl: null,
                        titleLinkMoreTooltipProps: null,
                        items: [
                            {
                                title: "Find a Property for Sale",
                                subtitle: "Let’s find your new home together",
                                linkUrl: `${WEBSITE_URL}/property/for-sale/in-ireland/`,
                                imgUrl: `/images/${themeName}/find-a-property-for-sale.jpg`,
                                imgAlt: "",
                            },
                            {
                                title: "Find a Property to Rent",
                                subtitle: "Let’s find your new home together",
                                linkUrl: `${WEBSITE_URL}/property/to-rent/in-ireland/`,
                                imgUrl: `/images/${themeName}/find-a-property-to-rent.jpg`,
                                imgAlt: "",
                            },
                            {
                                title: "Meet the Team",
                                subtitle: "Speak to one of our experts",
                                linkUrl: `${WEBSITE_URL}/contact-dng-estate-agents/find-a-dng-team-member`,
                                imgUrl: `/images/${themeName}/meet-the-team.jpg`,
                                imgAlt: "",
                            },
                        ],
                    },
                },
            ],
            subthemes: {
                propertyresult: {
                    props: {
                        container: {
                            order: ["media", "header", "aside"],
                            sx: theme => ({
                                flexDirection: "row",
                                width: "100%",
                                paddingTop: theme.spacing(1.125),
                                paddingBottom: theme.spacing(1.125),
                                [theme.breakpoints.up("sm")]: {
                                    flexDirection: "column",
                                    width: `calc(50% - ${theme.spacing(1.5)})`,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: theme.spacing(5),
                                    marginLeft: theme.spacing(0.75),
                                    marginRight: theme.spacing(0.75),
                                },
                                [theme.breakpoints.up("lg")]: {
                                    width: `calc(33.33% - ${theme.spacing(
                                        4.75
                                    )})`,
                                    marginLeft: theme.spacing(2.375),
                                    marginRight: theme.spacing(2.375),
                                    marginBottom: 0,
                                },
                            }),
                        },
                        header: {
                            sx: theme => ({
                                width: "calc(100% - 110px)",
                                [theme.breakpoints.up("sm")]: {
                                    width: "100%",
                                    marginTop: theme.spacing(3.25),
                                },
                                "& .propertyresult-address": {
                                    fontWeight: 500,
                                    [theme.breakpoints.up("sm")]: {
                                        fontSize: theme.bodySmallLarge,
                                        lineHeight: theme.lineHeightBLarge,
                                        marginBottom: theme.spacing(0.25),
                                    },
                                    "& .propertyresult-link-image": {
                                        color: theme.palette.grey.grey1,
                                    },
                                },
                                "& .propertyresult-price": {
                                    color: theme.palette.grey.grey2,
                                    [theme.breakpoints.up("sm")]: {
                                        fontSize: theme.bodyMedium,
                                        lineHeight: theme.lineHeightBMedium,
                                    },
                                },
                            }),
                        },
                        price: {
                            in: "header",
                            variant: "bodySmall",
                        },
                        flagBid: {
                            in: "media",
                            sx: theme => ({
                                display: "none",
                                [theme.breakpoints.up("sm")]: {
                                    display: "block",
                                    position: "absolute",
                                    bottom: "-25px",
                                    right: "10px",
                                    zIndex: "20",
                                },
                            }),
                        },
                        address: {
                            in: "header",
                            variant: "bodyMedium",
                            component: "header",
                        },
                        offer: {
                            in: "header",
                            variant: "bodyMedium",
                        },
                        rooms: {
                            in: null,
                        },
                        // "save": {
                        //     "in": "aside",
                        // },
                        // "removeProperty": {
                        //     "in": "aside",
                        // },
                        media: {
                            sx: theme => ({
                                position: "relative",
                                overflow: "hidden",
                                marginRight: theme.spacing(2.375),
                                "&.propertyresult-media": {
                                    width: "100%",
                                },
                                "& .propertyresult-link-image": {
                                    display: "block",
                                },
                                "& .propertyresult-image": {
                                    display: "block",
                                    width: "90px",
                                    [theme.breakpoints.up("sm")]: {
                                        width: "100%",
                                    },
                                },
                                [theme.breakpoints.up("sm")]: {
                                    marginRight: 0,
                                },
                            }),
                        },
                    },

                    // "typography": {
                    //     "h6": { "textTransform": "none" },
                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                    //     "subtitle2": { "textTransform": "none" },
                    // },

                    // "address": {
                    //     "separator": " • ",
                    //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                    // },
                },
            },
            // "tabsMyAccount": {
            //     "sx": (theme) => ({
            //         "flexGrow": 1,
            //         "marginBottom": theme.moduleSpacing,
            //         [theme.breakpoints.up('sm')]: {
            //             "marginBottom": theme.moduleSpacingSm,
            //         },
            //         [theme.breakpoints.up('lg')]: {
            //             "marginBottom": theme.moduleSpacingLg,
            //         },
            //         // "backgroundColor": theme.palette.background.paper,
            //         "& .tabsMyAccount-tabs": {
            //             "display": "none",
            //             "borderBottom": "1px solid #d8d8d8",
            //             "marginLeft": `-${theme.gridItemPmobile}`,
            //             "marginRight": `-${theme.gridItemPmobile}`,
            //             "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
            //             [theme.breakpoints.up('sm')]: {
            //                 "marginLeft": `-${theme.gridItemPtablet}`,
            //                 "marginRight": `-${theme.gridItemPtablet}`,
            //                 "paddingLeft": theme.gridItemPtablet,
            //                 "paddingRight": theme.gridItemPtablet,
            //                 "marginTop": 0,
            //             },
            //             [theme.breakpoints.up('md')]: {
            //                 "display": "flex",
            //             },
            //             [theme.breakpoints.up('lg')]: {
            //                 "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
            //                 "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
            //                 "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
            //                 "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
            //             },
            //             "& .tabsMyAccount-indicator": {
            //                 "height": "3px",
            //                 "backgroundColor": theme.palette.secondary.main,
            //             },
            //             "& .tabsMyAccount-scrollButtons": {
            //                 "alignItems": "flex-start",
            //                 "& svg": {
            //                     "marginTop": "12px",
            //                 },
            //                 "&.Mui-disabled": {
            //                     "opacity": 0.3,
            //                 },
            //             },
            //         },
            //         "& .tabsMyAccount-tab": {
            //             "fontFamily": theme.primaryFontFamily,
            //             "textTransform": "none",
            //             [theme.breakpoints.up('sm')]: {
            //                 "minWidth": "auto",
            //                 "paddingLeft": 0,
            //                 "paddingRight": 0,
            //                 "marginRight": theme.spacing(5),
            //             },
            //             [theme.breakpoints.up('md')]: {
            //                 "paddingBottom": theme.spacing(2.5),
            //             },
            //             "&.tabsMyAccount-selected": {
            //                 "fontWeight": 500,
            //             },
            //             "& .tabsMyAccount-wrapper": {
            //                 "fontSize": theme.bodySmall,
            //                 [theme.breakpoints.up('sm')]: {
            //                     "fontSize": theme.bodySmallLarge,
            //                     "lineHeight": theme.lineHeightBSLarge
            //                 }
            //             },
            //         },
            //         "& .tabsMyAccount-tabPanel": {
            //             "padding": 0,
            //         },
            //         "& .tabsMyAccount-tabPanelBox": {
            //             "padding": theme.spacing(0, 0, 0, 0),
            //             [theme.breakpoints.up('md')]: {
            //                 "padding": theme.spacing(7, 0, 0, 0),
            //             },
            //         }
            //     }),
            //     "subthemes": {
            //         "tabsMyAccount": {
            //             "sx": (theme) => ({
            //                 "flexGrow": 1,
            //                 "marginBottom": theme.moduleSpacing,
            //                 [theme.breakpoints.up('sm')]: {
            //                     "marginBottom": theme.moduleSpacingSm,
            //                 },
            //                 [theme.breakpoints.up('lg')]: {
            //                     "marginBottom": theme.moduleSpacingLg,
            //                 },
            //                 // "backgroundColor": theme.palette.background.paper,
            //                 "& .tabsMyAccount-tabs": {
            //                     "display": "flex",
            //                     "borderBottom": "1px solid #d8d8d8",
            //                     "marginLeft": `-${theme.gridItemPmobile}`,
            //                     "marginRight": `-${theme.gridItemPmobile}`,
            //                     [theme.breakpoints.up('sm')]: {
            //                         "marginLeft": `-${theme.gridItemPtablet}`,
            //                         "marginRight": `-${theme.gridItemPtablet}`,
            //                         "paddingLeft": theme.gridItemPtablet,
            //                         "paddingRight": theme.gridItemPtablet,
            //                     },
            //                     [theme.breakpoints.up('md')]: {
            //                         "borderBottom": "none",
            //                     },
            //                     [theme.breakpoints.up('lg')]: {
            //                         "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
            //                         "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
            //                         "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
            //                         "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
            //                     },
            //                     "& .tabsMyAccount-indicator": {
            //                         "height": "3px",
            //                         // "backgroundColor": theme.palette.primary.main,
            //                     },
            //                     "& .tabsMyAccount-scrollButtons": {
            //                         "alignItems": "flex-start",
            //                         "& svg": {
            //                             "marginTop": "12px",
            //                         },
            //                         "&.Mui-disabled": {
            //                             "opacity": 0.3,
            //                         },
            //                     },
            //                 },
            //                 "& .tabsMyAccount-tab": {
            //                     "textTransform": "none",
            //                     [theme.breakpoints.up('sm')]: {
            //                         "minWidth": "auto",
            //                         "paddingLeft": 0,
            //                         "paddingRight": 0,
            //                         "marginRight": theme.spacing(5),
            //                     },
            //                     [theme.breakpoints.up('md')]: {
            //                         "paddingBottom": theme.spacing(2.75),
            //                     },
            //                     "&.tabsMyAccount-selected": {
            //                         "fontWeight": 500,
            //                     },
            //                     "& .tabsMyAccount-wrapper": {
            //                         "fontSize": theme.bodySmall,
            //                         [theme.breakpoints.up('sm')]: {
            //                             "fontSize": theme.bodySmallLarge,
            //                             "lineHeight": theme.lineHeightBSLarge
            //                         }
            //                     },
            //                 },
            //                 "& .tabsMyAccount-tabPanel": {
            //                     "padding": 0,
            //                 },
            //                 "& .tabsMyAccount-tabPanelBox": {
            //                     "padding": theme.spacing(2.75, 0, 0, 0),
            //                     [theme.breakpoints.up('md')]: {
            //                         "padding": theme.spacing(2.75, 0, 0, 0),
            //                     },
            //                 }
            //             }),
            //         },
            //     },
            // },
            // "subthemes": {
            //     "tabsMyAccount": {
            //         "props": {
            //             "tabPanelBox": (theme) => ({
            //                 "padding": theme.spacing(2.75,0,0,0),
            //                 [theme.breakpoints.up('sm')]: {
            //                     "padding": theme.spacing(7,0,0,0),
            //                 },
            //             }),
            //         },
            //     },
            // },
        },
        myPropertyPageSeller: {
            subthemes: {
                mainContent: {
                    // Remove top content spacing on mobile
                    sx: theme => ({
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        flexGrow: 1,
                        padding: theme.spacing(
                            0,
                            0,
                            theme.myAccountContentPBmobile + 8,
                            0
                        ),
                        [theme.breakpoints.up("sm")]: {
                            padding: theme.spacing(
                                0,
                                0,
                                theme.myAccountContentPBtablet + 8,
                                0
                            ),
                        },
                        [theme.breakpoints.up("md")]: {
                            width: `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            padding: theme.spacing(
                                theme.myAccountContentPTtablet,
                                0,
                                theme.myAccountContentPBtablet,
                                0
                            ),
                        },
                        [theme.breakpoints.up("lg")]: {
                            padding: theme.spacing(
                                theme.myAccountContentPTDesktop,
                                0,
                                theme.myAccountContentPBDesktop,
                                0
                            ),
                        },
                        "& .mainContent-wrapper": {
                            flexGrow: 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                tabsMyAccount: {
                    sx: theme => ({
                        flexGrow: 1,
                        marginBottom: theme.moduleSpacing,
                        [theme.breakpoints.up("sm")]: {
                            marginBottom: theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up("lg")]: {
                            marginBottom: theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            display: "none",
                            borderBottom: "1px solid #d8d8d8",
                            marginLeft: `-${theme.gridItemPmobile}`,
                            marginRight: `-${theme.gridItemPmobile}`,
                            marginTop: `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: `-${theme.gridItemPtablet}`,
                                marginRight: `-${theme.gridItemPtablet}`,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                                marginTop: 0,
                            },
                            [theme.breakpoints.up("md")]: {
                                display: "flex",
                            },
                            [theme.breakpoints.up("lg")]: {
                                marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                height: "3px",
                                backgroundColor: theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                alignItems: "flex-start",
                                "& svg": {
                                    marginTop: "12px",
                                },
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            fontFamily: theme.secondaryFontFamily,
                            fontSize: theme.bodySmall,
                            textTransform: "none",
                            color: theme.palette.grey.grey2,
                            [theme.breakpoints.up("sm")]: {
                                minWidth: "auto",
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: theme.spacing(5),
                                fontSize: theme.bodySmallLarge,
                                lineHeight: theme.lineHeightBSLarge,
                                fontWeight: 400,
                            },
                            [theme.breakpoints.up("md")]: {
                                paddingBottom: theme.spacing(2.5),
                            },
                            "&.tabsMyAccount-selected": {
                                color: theme.palette.secondary.main,
                                fontWeight: 500,
                            },
                            "& .tabsMyAccount-wrapper": {
                                fontSize: theme.bodySmall,
                                lineHeight: theme.lineHeightBSmall,
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: theme.bodySmallLarge,
                                    lineHeight: theme.lineHeightBSLarge,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            padding: 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            padding: theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up("md")]: {
                                padding: theme.spacing(7, 0, 0, 0),
                            },
                        },
                    }),
                    subthemes: {
                        tabsMyAccount: {
                            sx: theme => ({
                                flexGrow: 1,
                                marginBottom: theme.moduleSpacing,
                                [theme.breakpoints.up("sm")]: {
                                    marginBottom: theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up("lg")]: {
                                    marginBottom: theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    display: "flex",
                                    borderBottom: "1px solid #d8d8d8",
                                    marginLeft: `-${theme.gridItemPmobile}`,
                                    marginRight: `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up("sm")]: {
                                        marginLeft: `-${theme.gridItemPtablet}`,
                                        marginRight: `-${theme.gridItemPtablet}`,
                                        paddingLeft: theme.gridItemPtablet,
                                        paddingRight: theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        borderBottom: "none",
                                    },
                                    [theme.breakpoints.up("lg")]: {
                                        marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                        paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        height: "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        alignItems: "flex-start",
                                        "& svg": {
                                            marginTop: "12px",
                                        },
                                        "&.Mui-disabled": {
                                            opacity: 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    fontFamily: theme.secondaryFontFamily,
                                    fontSize: theme.bodySmall,
                                    textTransform: "none",
                                    color: theme.palette.grey.grey2,
                                    [theme.breakpoints.up("sm")]: {
                                        minWidth: "auto",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        marginRight: theme.spacing(5),
                                        fontSize: theme.bodySmallLarge,
                                        lineHeight: theme.lineHeightBSLarge,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        paddingBottom: theme.spacing(2.5),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        color: theme.palette.secondary.main,
                                        fontWeight: 500,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        fontSize: theme.bodySmall,
                                        lineHeight: theme.lineHeightBSmall,
                                        [theme.breakpoints.up("sm")]: {
                                            fontSize: theme.bodySmallLarge,
                                            lineHeight: theme.lineHeightBSLarge,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    padding: 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    padding: theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up("md")]: {
                                        padding: theme.spacing(2.75, 0, 0, 0),
                                    },
                                },
                            }),
                        },
                    },
                },
            },
        },
        myPropertyPageLandlord: {
            subthemes: {
                mainContent: {
                    // Remove top content spacing on mobile
                    sx: theme => ({
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        flexGrow: 1,
                        padding: theme.spacing(
                            0,
                            0,
                            theme.myAccountContentPBmobile + 8,
                            0
                        ),
                        [theme.breakpoints.up("sm")]: {
                            padding: theme.spacing(
                                0,
                                0,
                                theme.myAccountContentPBtablet + 8,
                                0
                            ),
                        },
                        [theme.breakpoints.up("md")]: {
                            width: `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            padding: theme.spacing(
                                theme.myAccountContentPTtablet,
                                0,
                                theme.myAccountContentPBtablet,
                                0
                            ),
                        },
                        [theme.breakpoints.up("lg")]: {
                            padding: theme.spacing(
                                theme.myAccountContentPTDesktop,
                                0,
                                theme.myAccountContentPBDesktop,
                                0
                            ),
                        },
                        "& .mainContent-wrapper": {
                            flexGrow: 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                tabsMyAccount: {
                    sx: theme => ({
                        flexGrow: 1,
                        marginBottom: theme.moduleSpacing,
                        [theme.breakpoints.up("sm")]: {
                            marginBottom: theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up("lg")]: {
                            marginBottom: theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            display: "none",
                            borderBottom: "1px solid #d8d8d8",
                            marginLeft: `-${theme.gridItemPmobile}`,
                            marginRight: `-${theme.gridItemPmobile}`,
                            marginTop: `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: `-${theme.gridItemPtablet}`,
                                marginRight: `-${theme.gridItemPtablet}`,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                                marginTop: 0,
                            },
                            [theme.breakpoints.up("md")]: {
                                display: "flex",
                            },
                            [theme.breakpoints.up("lg")]: {
                                marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                height: "3px",
                                backgroundColor: theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                alignItems: "flex-start",
                                "& svg": {
                                    marginTop: "12px",
                                },
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            fontFamily: theme.secondaryFontFamily,
                            fontSize: theme.bodySmall,
                            textTransform: "none",
                            color: theme.palette.grey.grey2,
                            [theme.breakpoints.up("sm")]: {
                                minWidth: "auto",
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: theme.spacing(5),
                                fontSize: theme.bodySmallLarge,
                                lineHeight: theme.lineHeightBSLarge,
                                fontWeight: 400,
                            },
                            [theme.breakpoints.up("md")]: {
                                paddingBottom: theme.spacing(2.5),
                            },
                            "&.tabsMyAccount-selected": {
                                color: theme.palette.secondary.main,
                                fontWeight: 500,
                            },
                            "& .tabsMyAccount-wrapper": {
                                fontSize: theme.bodySmall,
                                lineHeight: theme.lineHeightBSmall,
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: theme.bodySmallLarge,
                                    lineHeight: theme.lineHeightBSLarge,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            padding: 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            padding: theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up("md")]: {
                                padding: theme.spacing(7, 0, 0, 0),
                            },
                        },
                    }),
                    subthemes: {
                        tabsMyAccount: {
                            sx: theme => ({
                                flexGrow: 1,
                                marginBottom: theme.moduleSpacing,
                                [theme.breakpoints.up("sm")]: {
                                    marginBottom: theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up("lg")]: {
                                    marginBottom: theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    display: "flex",
                                    borderBottom: "1px solid #d8d8d8",
                                    marginLeft: `-${theme.gridItemPmobile}`,
                                    marginRight: `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up("sm")]: {
                                        marginLeft: `-${theme.gridItemPtablet}`,
                                        marginRight: `-${theme.gridItemPtablet}`,
                                        paddingLeft: theme.gridItemPtablet,
                                        paddingRight: theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        borderBottom: "none",
                                    },
                                    [theme.breakpoints.up("lg")]: {
                                        marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                        paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        height: "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        alignItems: "flex-start",
                                        "& svg": {
                                            marginTop: "12px",
                                        },
                                        "&.Mui-disabled": {
                                            opacity: 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    fontFamily: theme.secondaryFontFamily,
                                    fontSize: theme.bodySmall,
                                    textTransform: "none",
                                    color: theme.palette.grey.grey2,
                                    [theme.breakpoints.up("sm")]: {
                                        minWidth: "auto",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        marginRight: theme.spacing(5),
                                        fontSize: theme.bodySmallLarge,
                                        lineHeight: theme.lineHeightBSLarge,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        paddingBottom: theme.spacing(2.5),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        color: theme.palette.secondary.main,
                                        fontWeight: 500,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        fontSize: theme.bodySmall,
                                        lineHeight: theme.lineHeightBSmall,
                                        [theme.breakpoints.up("sm")]: {
                                            fontSize: theme.bodySmallLarge,
                                            lineHeight: theme.lineHeightBSLarge,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    padding: 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    padding: theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up("md")]: {
                                        padding: theme.spacing(2.75, 0, 0, 0),
                                    },
                                },
                            }),
                        },
                    },
                },
            },
        },
        calendarPage: {
            props: {
                showValuation: false,
                showContactInsteadOfValaution: false,
            },
        },
        inbox: {},
        loginPage: {},
        signUpPage: {},
        unsubscribePage: {},
        resetPassEmailPage: {},
        resetPassPage: {},
        profilePage: {
            props: {
                conversationalFormEnabled: true,
                // This can be a array or objects
                tab: {
                    default: "personal-details",
                    // This can be a array or objects
                    items: [
                        "personal-details",
                        "property-preference",
                        "notification-preference",
                        "change-password",
                        "logout",
                    ],
                    // "items": {
                    //     'personal-details': {},
                    //     ...
                    // },
                },
            },
            subthemes: {
                // "simpleModule": {
                //     "cardModule": {
                //         "props": { // props are avaialble from Paper comp
                //             // "elevation": 2,
                //         },
                //         "sx": (theme) => ({
                //             "root": {
                //                 "padding": theme.modulePadding,
                //                 "borderRadius": theme.moduleBorderRadius,
                //                 [theme.breakpoints.up('lg')]: {
                //                     "marginTop": theme.spacing(0),
                //                 }
                //             },
                //         }),
                //     },
                // },
            },
        },
        myPropertyPageTenant: {
            subthemes: {
                mainContent: {
                    // Remove top content spacing on mobile
                    sx: theme => ({
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        flexGrow: 1,
                        padding: theme.spacing(
                            0,
                            0,
                            theme.myAccountContentPBmobile + 8,
                            0
                        ),
                        [theme.breakpoints.up("sm")]: {
                            padding: theme.spacing(
                                0,
                                0,
                                theme.myAccountContentPBtablet + 8,
                                0
                            ),
                        },
                        [theme.breakpoints.up("md")]: {
                            width: `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            padding: theme.spacing(
                                theme.myAccountContentPTtablet,
                                0,
                                theme.myAccountContentPBtablet,
                                0
                            ),
                        },
                        [theme.breakpoints.up("lg")]: {
                            padding: theme.spacing(
                                theme.myAccountContentPTDesktop,
                                0,
                                theme.myAccountContentPBDesktop,
                                0
                            ),
                        },
                        "& .mainContent-wrapper": {
                            flexGrow: 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                tabsMyAccount: {
                    sx: theme => ({
                        flexGrow: 1,
                        marginBottom: theme.moduleSpacing,
                        [theme.breakpoints.up("sm")]: {
                            marginBottom: theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up("lg")]: {
                            marginBottom: theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            display: "none",
                            borderBottom: "1px solid #d8d8d8",
                            marginLeft: `-${theme.gridItemPmobile}`,
                            marginRight: `-${theme.gridItemPmobile}`,
                            marginTop: `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: `-${theme.gridItemPtablet}`,
                                marginRight: `-${theme.gridItemPtablet}`,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                                marginTop: 0,
                            },
                            [theme.breakpoints.up("md")]: {
                                display: "flex",
                            },
                            [theme.breakpoints.up("lg")]: {
                                marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                height: "3px",
                                backgroundColor: theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                alignItems: "flex-start",
                                "& svg": {
                                    marginTop: "12px",
                                },
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            fontFamily: theme.secondaryFontFamily,
                            fontSize: theme.bodySmall,
                            textTransform: "none",
                            color: theme.palette.grey.grey2,
                            [theme.breakpoints.up("sm")]: {
                                minWidth: "auto",
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: theme.spacing(5),
                                fontSize: theme.bodySmallLarge,
                                lineHeight: theme.lineHeightBSLarge,
                                fontWeight: 400,
                            },
                            [theme.breakpoints.up("md")]: {
                                paddingBottom: theme.spacing(2.5),
                            },
                            "&.tabsMyAccount-selected": {
                                color: theme.palette.secondary.main,
                                fontWeight: 500,
                            },
                            "& .tabsMyAccount-wrapper": {
                                fontSize: theme.bodySmall,
                                lineHeight: theme.lineHeightBSmall,
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: theme.bodySmallLarge,
                                    lineHeight: theme.lineHeightBSLarge,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            padding: 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            padding: theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up("md")]: {
                                padding: theme.spacing(7, 0, 0, 0),
                            },
                        },
                    }),
                    subthemes: {
                        tabsMyAccount: {
                            sx: theme => ({
                                flexGrow: 1,
                                marginBottom: theme.moduleSpacing,
                                [theme.breakpoints.up("sm")]: {
                                    marginBottom: theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up("lg")]: {
                                    marginBottom: theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    display: "flex",
                                    borderBottom: "1px solid #d8d8d8",
                                    marginLeft: `-${theme.gridItemPmobile}`,
                                    marginRight: `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up("sm")]: {
                                        marginLeft: `-${theme.gridItemPtablet}`,
                                        marginRight: `-${theme.gridItemPtablet}`,
                                        paddingLeft: theme.gridItemPtablet,
                                        paddingRight: theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        borderBottom: "none",
                                    },
                                    [theme.breakpoints.up("lg")]: {
                                        marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                        paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        height: "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        alignItems: "flex-start",
                                        "& svg": {
                                            marginTop: "12px",
                                        },
                                        "&.Mui-disabled": {
                                            opacity: 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    fontFamily: theme.secondaryFontFamily,
                                    fontSize: theme.bodySmall,
                                    textTransform: "none",
                                    color: theme.palette.grey.grey2,
                                    [theme.breakpoints.up("sm")]: {
                                        minWidth: "auto",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        marginRight: theme.spacing(5),
                                        fontSize: theme.bodySmallLarge,
                                        lineHeight: theme.lineHeightBSLarge,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        paddingBottom: theme.spacing(2.5),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        color: theme.palette.secondary.main,
                                        fontWeight: 500,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        fontSize: theme.bodySmall,
                                        lineHeight: theme.lineHeightBSmall,
                                        [theme.breakpoints.up("sm")]: {
                                            fontSize: theme.bodySmallLarge,
                                            lineHeight: theme.lineHeightBSLarge,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    padding: 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    padding: theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up("md")]: {
                                        padding: theme.spacing(2.75, 0, 0, 0),
                                    },
                                },
                            }),
                        },
                    },
                },
            },
        },
        myPropertyPageBuyer: {
            props: {
                offer: {
                    noOfferMessage:
                        "All bids on this property will appear here after you place a bid.",
                    actionTitle: "Bids",
                    identityChecks: identityChecks,
                    listDisclaimer:
                        "<span>Submitted bids are Subject to Contract / Contract Denied</span>",
                },
                tab: {
                    // This can be a array or objects
                    items: [
                        "overview",
                        "appointments",
                        "feedback",
                        "bids",
                        "proofoffunds",
                    ],
                },
                overviewTabs: {
                    items: ["Overview", "Bids", "Activity"],
                },
            },
            subthemes: {
                mainContent: {
                    // Remove top content spacing on mobile
                    sx: theme => ({
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        flexGrow: 1,
                        padding: theme.spacing(
                            0,
                            0,
                            theme.myAccountContentPBmobile + 8,
                            0
                        ),
                        [theme.breakpoints.up("sm")]: {
                            padding: theme.spacing(
                                0,
                                0,
                                theme.myAccountContentPBtablet + 8,
                                0
                            ),
                        },
                        [theme.breakpoints.up("md")]: {
                            width: `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            padding: theme.spacing(
                                theme.myAccountContentPTtablet,
                                0,
                                theme.myAccountContentPBtablet,
                                0
                            ),
                        },
                        [theme.breakpoints.up("lg")]: {
                            padding: theme.spacing(
                                theme.myAccountContentPTDesktop,
                                0,
                                theme.myAccountContentPBDesktop,
                                0
                            ),
                        },
                        "& .mainContent-wrapper": {
                            flexGrow: 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                tabsMyAccount: {
                    sx: theme => ({
                        flexGrow: 1,
                        marginBottom: theme.moduleSpacing,
                        [theme.breakpoints.up("sm")]: {
                            marginBottom: theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up("lg")]: {
                            marginBottom: theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            display: "none",
                            borderBottom: "1px solid #d8d8d8",
                            marginLeft: `-${theme.gridItemPmobile}`,
                            marginRight: `-${theme.gridItemPmobile}`,
                            marginTop: `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: `-${theme.gridItemPtablet}`,
                                marginRight: `-${theme.gridItemPtablet}`,
                                paddingLeft: theme.gridItemPtablet,
                                paddingRight: theme.gridItemPtablet,
                                marginTop: 0,
                            },
                            [theme.breakpoints.up("md")]: {
                                display: "flex",
                            },
                            [theme.breakpoints.up("lg")]: {
                                marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                height: "3px",
                                backgroundColor: theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                alignItems: "flex-start",
                                "& svg": {
                                    marginTop: "12px",
                                },
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            fontFamily: theme.secondaryFontFamily,
                            fontSize: theme.bodySmall,
                            textTransform: "none",
                            color: theme.palette.grey.grey2,
                            [theme.breakpoints.up("sm")]: {
                                minWidth: "auto",
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginRight: theme.spacing(5),
                                fontSize: theme.bodySmallLarge,
                                lineHeight: theme.lineHeightBSLarge,
                                fontWeight: 400,
                            },
                            [theme.breakpoints.up("md")]: {
                                paddingBottom: theme.spacing(2.5),
                            },
                            "&.tabsMyAccount-selected": {
                                color: theme.palette.secondary.main,
                                fontWeight: 500,
                            },
                            "& .tabsMyAccount-wrapper": {
                                fontSize: theme.bodySmall,
                                lineHeight: theme.lineHeightBSmall,
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: theme.bodySmallLarge,
                                    lineHeight: theme.lineHeightBSLarge,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            padding: 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            padding: theme.spacing(0, 0, 0, 0),
                            "& .offerBtn": {
                                maxWidth: "310px",
                            },
                            [theme.breakpoints.up("md")]: {
                                padding: theme.spacing(7, 0, 0, 0),
                            },
                        },
                    }),
                    subthemes: {
                        tabsMyAccount: {
                            sx: theme => ({
                                flexGrow: 1,
                                marginBottom: theme.moduleSpacing,
                                [theme.breakpoints.up("sm")]: {
                                    marginBottom: theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up("lg")]: {
                                    marginBottom: theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    display: "flex",
                                    borderBottom: "1px solid #d8d8d8",
                                    marginLeft: `-${theme.gridItemPmobile}`,
                                    marginRight: `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up("sm")]: {
                                        marginLeft: `-${theme.gridItemPtablet}`,
                                        marginRight: `-${theme.gridItemPtablet}`,
                                        paddingLeft: theme.gridItemPtablet,
                                        paddingRight: theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        borderBottom: "none",
                                    },
                                    [theme.breakpoints.up("lg")]: {
                                        marginLeft: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        marginRight: `calc(-${theme.gridItemPdesktop} * 2)`,
                                        paddingLeft: `calc(${theme.gridItemPdesktop} * 2)`,
                                        paddingRight: `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        height: "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        alignItems: "flex-start",
                                        "& svg": {
                                            marginTop: "12px",
                                        },
                                        "&.Mui-disabled": {
                                            opacity: 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    fontFamily: theme.secondaryFontFamily,
                                    fontSize: theme.bodySmall,
                                    textTransform: "none",
                                    color: theme.palette.grey.grey2,
                                    [theme.breakpoints.up("sm")]: {
                                        minWidth: "auto",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        marginRight: theme.spacing(5),
                                        fontSize: theme.bodySmallLarge,
                                        lineHeight: theme.lineHeightBSLarge,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        paddingBottom: theme.spacing(2.5),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        color: theme.palette.secondary.main,
                                        fontWeight: 500,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        fontSize: theme.bodySmall,
                                        lineHeight: theme.lineHeightBSmall,
                                        [theme.breakpoints.up("sm")]: {
                                            fontSize: theme.bodySmallLarge,
                                            lineHeight: theme.lineHeightBSLarge,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    padding: 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    padding: theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up("md")]: {
                                        padding: theme.spacing(2.75, 0, 0, 0),
                                    },
                                },
                            }),
                        },
                    },
                },
            },
        },
        propertyResultsPageFilters: {
            // options: src/components/MyAccount/pages/properties/defaults.js
        },
        propertyResultsPage: {
            subthemes: {
                results: {
                    // default property result styling
                    sx: theme => ({
                        [theme.breakpoints.up("sm")]: {
                            width: "auto",
                            margin: theme.spacing(0, -0.75),
                        },
                        [theme.breakpoints.up("lg")]: {
                            margin: theme.spacing(0, -2.375),
                        },
                    }),
                    subthemes: {
                        propertyresult: {
                            props: {
                                container: {
                                    order: ["media", "header", "aside"],
                                    sx: theme => ({
                                        flexDirection: "column",
                                        width: "100%",
                                        marginBottom: theme.spacing(3),
                                        [theme.breakpoints.up("sm")]: {
                                            width: `calc(50% - ${theme.spacing(
                                                1.5
                                            )})`,
                                            marginBottom: theme.spacing(5),
                                            marginLeft: theme.spacing(0.75),
                                            marginRight: theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up("lg")]: {
                                            width: `calc(33.33% - ${theme.spacing(
                                                4.75
                                            )})`,
                                            marginLeft: theme.spacing(2.375),
                                            marginRight: theme.spacing(2.375),
                                        },
                                    }),
                                },
                                header: {
                                    sx: theme => ({
                                        width: "100%",
                                        marginTop: theme.spacing(1.5),
                                        [theme.breakpoints.up("sm")]: {
                                            marginTop: theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            fontWeight: 500,
                                            [theme.breakpoints.up("sm")]: {
                                                fontSize: theme.bodySmallLarge,
                                                lineHeight:
                                                    theme.lineHeightBLarge,
                                                marginBottom:
                                                    theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                color: theme.palette.grey.grey1,
                                            },
                                        },
                                        "& .propertyresult-rooms": {
                                            // "paddingBottom": theme.spacing(1.25),
                                        },
                                    }),
                                },
                                aside: {
                                    sx: theme => ({
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        "& .propertyresult-price": {
                                            marginRight: "auto",
                                            [theme.breakpoints.up("sm")]: {
                                                fontSize: theme.bodyMedium,
                                                lineHeight:
                                                    theme.lineHeightBMedium,
                                            },
                                        },
                                    }),
                                },
                                price: {
                                    in: "aside",
                                    variant: "bodySmall",
                                    component: "p",
                                },
                                address: {
                                    in: "header",
                                    variant: "bodyMedium",
                                    component: "header",
                                },
                                offer: {
                                    in: "header",
                                    variant: "bodyMedium",
                                },
                                rooms: {
                                    in: "header",
                                },
                                bookviewing: {
                                    in: "aside",
                                },
                                save: {
                                    in: "media",
                                },
                                "360tour": {
                                    in: "media",
                                },
                                // "removeProperty": {
                                //     "in": "aside",
                                // },
                                media: {
                                    sx: theme => ({
                                        position: "relative",
                                        overflow: "hidden",
                                        "& .propertyresult-link-image": {
                                            display: "block",
                                            "&:after": {
                                                content: "''",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                background: "rgba(0,0,0,0.2)",
                                                zIndex: "2",
                                                pointerEvents: "none",
                                            },
                                        },
                                        "& .propertyresult-image": {
                                            display: "block",
                                            width: "100%",
                                        },
                                        "& .propertyresult-save": {
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "20",
                                            "& svg": {
                                                color: "#fff",
                                            },
                                        },
                                        "& .propertyresult-360tour": {
                                            position: "absolute",
                                            bottom: "15px",
                                            right: "15px",
                                            width: "auto",
                                            zIndex: 20,
                                        },
                                    }),
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            addressFormat: {
                                separator: " , ",
                                values: p =>
                                    [
                                        p.display_address,
                                        p.street_name,
                                        p.district,
                                        p.area,
                                    ]
                                        .filter(x => x)
                                        .shift(),
                                // "values": (p) => console.log(p)
                            },
                            roomsFormat: {
                                separator: " ",
                                values: p => [
                                    p.bedrooms,
                                    p.receptions,
                                    p.bathrooms,
                                ],
                            },
                            linkFormat: {
                                separator: "-",
                                target: "_blank",
                                // "values": (p) => propertyLink(p)
                                // "values": (p) => console.log(p)
                            },
                            // "lettingsPricePrefix": "",
                            lettingsPriceSuffix: " PCM",
                            // "salesPricePrefix": "",
                            // "salesPriceSuffix": "",
                        },
                    },
                },
                propertyAreaInfo: {},
                propertyDetailsRooms: {
                    sx: theme => ({
                        display: "flex",
                        flexDirection: "row",
                        "& .roomsItem": {
                            display: "flex",
                            flexDirection: "row",
                            marginRight: theme.spacing(2.25),
                            [theme.breakpoints.up("sm")]: {
                                marginRight: theme.spacing(3.25),
                            },
                            "& svg": {
                                marginRight: theme.spacing(1),
                                color: theme.palette.secondary.main,
                            },
                            "& .roomsItem-text": {},
                        },
                    }),
                },
                bookViewingBtn: {
                    props: {
                        btnLabelProps: "Book viewing online",
                        btnProps: {
                            color: "primary",
                            variant: "text",
                            size: "small",
                        },
                    },
                },
            },
        },
        propertyResultsMapPage: {},
        carousel: {
            // options: src/components/MyAccount/carousel/defaults.js
        },
        propertyAreaInfo: {
            // options: src/components/MyAccount/property/components/areaInfo/defaults.js
        },
        propertyDetailsRooms: {
            // options: src/components/MyAccount/property/components/rooms/defaults.js
        },
        locratingIframe: {
            // options: src/components/MyAccount/property/components/map/defaultsLocratingMap.js
        },
        videoTour: {
            // options: src/components/MyAccount/property/components/videoTour/defaults.js
        },
        virtualTour: {
            // options: src/components/MyAccount/property/components/virtualTour/defaults.js
        },
        performanceTabs: {
            // options: src/components/MyAccount/pages/my-property/common/performance/defaults.js
        },
        facebookPerformance: {
            // options: src/components/MyAccount/pages/my-property/common/performance/facebook/defaults.js
        },
        performanceChart: {
            // options: src/components/MyAccount/pages/my-property/common/performance/performanceChart/defaults.js
        },
        lineChart: {
            // options: src/components/MyAccount/charts/lineChart/defaults.js
        },
        propertyDetailsPage: {
            subthemes: {
                propertyDetailsRooms: {
                    props: {
                        propertyBedsText: "Bedrooms",
                        propertyBedsSingleText: "Bedroom",
                        propertyBathsText: "Bathrooms",
                        propertyBathsSingleText: "Bathroom",
                        propertyRecText: "Receptions",
                        propertyRecSingleText: "Reception",
                    },
                    sx: theme => ({
                        marginBottom: theme.spacing(2.25),
                        display: "flex",
                        flexDirection: "column",
                        [theme.breakpoints.up("sm")]: {
                            flexDirection: "row",
                        },
                        "& .roomsItem": {
                            display: "flex",
                            flexDirection: "row",
                            [theme.breakpoints.up("sm")]: {
                                marginRight: theme.spacing(3.25),
                            },
                            "& svg": {
                                marginRight: theme.spacing(1),
                            },
                        },
                    }),
                },
            },
        },
        newsOverviewItem: {},
        newsOverviewPage: {},
        newsDetailsPage: {
            // options: src/components/MyAccount/blog/details/defaults.js
        },
        "404Page": {
            // options: src/components/MyAccount/404page/defaults.js
        },
        myaccountFooter: {
            // options: src/components/MyAccount/footer/defaults.js
            props: {
                footerMenus: [
                    {
                        id: "link1",
                        name: "Terms & Conditions",
                        url: termsSiteLink,
                        target: "_blank",
                    },
                    {
                        id: "link2",
                        name: "Privacy Policy",
                        url: privacySiteLink,
                        target: "_blank",
                    },
                ],
            },
        },
        // },
        // },
        appFeatures: {
            include: [FAQ],
            // "exclude": [],
            allFeaturesEnabled: false,
        },
    },
}
